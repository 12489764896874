import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject, Observable, BehaviorSubject, noop } from 'rxjs';
import { map } from 'rxjs/operators';
import { app } from 'environments/app';

import { UserPreferencesService } from './userpreferences.service';
import { UnitMeasurementsService } from './unitmeasurements.service';

import { JwtHelper } from '../model/jwthelper.model';
import { EndpointsService } from './endpoints.service';
import { DynamicScriptLoaderService } from './dynamic-script-loader.service';

import * as DateTime from 'luxon';

@Injectable()
export class AuthService {
    private defaultTimezoneOffset: number = null;
    private _token: string = null;
    private _clientDataServer: string = null;
    private _clientDataServerId: number = null;
    private _username: string = null;
    private _frontendPreferences: any = null;
    private _rememberMe = false;
    private _email: string = null;
    private _hiddenHeader = false;
    private _userId: number = null;
    private _clientId: number = null;
    private _tachoCredentials: any = null;
    private _angularRoutes: any = [];
    private _userModulesOptions: any = null;
    private _clientName: string = null;
    private _phone: string = null;
    private _isSuspended = false;
    private _isSuspendedToCargobox = false;
    private _isSuspendedToHugoPremium = false;
    private _suspendedReason: number = null;
    private _slackWebhookUrl: string = null;

    public setFullNameSubject = new Subject<any>();
    public setProfilePictureSubject = new Subject<any>();
    public requiresAttentionSubject = new Subject<string>();
    public BAD_PAYER_REASON = 1;
    public DOCUMENT_MISSING_REASON = 2;

    public static USER_RIGHT_RER_DASHBOARD = 'rer-dashboard';
    public static USER_RIGHT_RER_STREET_GEOZONE = 'rer-street-geozone';
    public static USER_RIGHT_RER_ROUTE = 'rer-route';
    public static USER_RIGHT_RER_ROUTE_PLANNING_SEARCH = 'rer-route-planning-search';
    public static USER_RIGHT_RER_PLANNING = 'rer-planning';
    public static USER_RIGHT_RER_CALENDAR_INVOICEABLE_READINGS_EXPORT = 'rer-calendar-invoiceable-readings-export';
    public static USER_RIGHT_RER_PLANNING_DETAILS = 'rer-planning-details';
    public static USER_RIGHT_RER_PLANNING_EDIT = 'rer-planning-edit';
    public static USER_RIGHT_RER_PLANNING_ADDRESS = 'rer-planning-address';
    public static USER_RIGHT_RER_IP_CAMERA = 'rer-ip-camera';
    public static USER_RIGHT_RER_IMAGE_GALLERY = 'rer-image-gallery';
    public static USER_RIGHT_RER_CLIENTS = 'rer-client';
    public static USER_RIGHT_RER_BINS = 'rer-object';
    public static USER_RIGHT_RER_CLIENT_ASSOCIATION = 'user-object-allocation';
    public static USER_RIGHT_RER_CLIENT_BIN_SETUP_DOC_ASSOCIATION = 'user-bin-setup-document-allocation';
    public static USER_RIGHT_RER_DATA_IMPORT = 'data-import-rer';
    public static USER_RIGHT_RER_DATA_EXPORT = 'data-export-rer';
    public static USER_RIGHT_RER_RFID_MANAGEMENT = 'rfid-management';
    public static USER_RIGHT_RER_UNALLOCATED_RER_BINS = 'unallocated-objects';
    public static USER_RIGHT_RER_EMPLOYEE = 'rer-employee';
    public static USER_RIGHT_RER_SETTINGS = 'rer-setting';
    public static USER_RIGHT_RER_INCIDENT = 'rer-incident';
    public static USER_RIGHT_RER_INCIDENT_DETAILS = 'rer-incident-administration';
    public static USER_RIGHT_RER_INCIDENT_DELETE = 'rer-incident-delete';
    public static USER_RIGHT_RER_POWER_BI_REPORT = 'rer-power-bi-report';
    public static USER_RIGHT_RER_BIN_OWNERSHIP_SETTINGS = 'rer-bin-ownership-settings';
    public static USER_RIGHT_RER_EXPORT_TAG_HISTORY = 'rer-export-tag-history';
    public static USER_RIGHT_RER_ADDRESS_GEOZONE = 'rer-client-address-geozone';
    public static USER_RIGHT_RER_COLLECTION_BY_WEIGHT = 'rer-collection-by-weight';
    public static USER_RIGHT_RER_COLLECTION_DECISIONS_BY_WEIGHT = 'collection-decisions-by-weight';
    public static USER_RIGHT_RER_REPORTS_SENT_AUTOMATICALLY = 'report-sent-automatically';
    public static USER_RIGHT_RER_GENERAL_REPORTS = 'general-reports';
    public static USER_RIGHT_RER_UNLOADING_AREAS = 'unloading-areas';
    public static USER_RIGHT_RER_OPERATION_OF_WEIGHING_RECEIPTS = 'operation-of-weighing-receipts';
    public static USER_RIGHT_RER_EXPORT_BIN_SETUPS = 'rer-bin-setups-export';
    public static USER_RIGHT_RER_VERBAL_PROCESS = 'rer-verbal-process';
    public static USER_RIGHT_RER_VERBAL_PROCESS_DELETE = 'rer-verbal-process-delete';

    public static USER_RIGHT_CONTACTS_AGENDA = '/contacts-agenda'

    //  this is for dispatcher implementation
    public sendMessageSubject = new Subject<{
        to_user_id: number,
        message: string,
    }>();

    public userModulesOptionsSubject = new BehaviorSubject<boolean>(true);

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private zone: NgZone,
        private unitMeasurementsService: UnitMeasurementsService,
        private userPreferencesService: UserPreferencesService,
        private endpointsService: EndpointsService,
        private dynamicScriptLoader: DynamicScriptLoaderService
    ) {
        this.endpointsService.setAuthService(this);
        const localCurrentUserJson: string = localStorage.getItem('currentUser');
        if (localCurrentUserJson) {
            sessionStorage.setItem('currentUser', localCurrentUserJson);
        }

        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        if (currentUser) {
            this.token = currentUser.token;
            this.clientDataServer = currentUser['client_data_server'];
            this.clientDataServerId = currentUser['client_data_server_id'];
            this.username = currentUser['username'];
            this.frontendPreferences = JSON.parse(currentUser['frontendPreferences']);
            this.rememberMe = currentUser['rememberme'];
            this.email = currentUser['email'];
            this.userId = currentUser['user_id'];
            this.clientId = currentUser['client_id'];
            this.clientName = currentUser['client_name'];
            this.phone = currentUser['phone'];
            this.angularRoutes = currentUser['userRights'];
            this.isSuspended = currentUser['is_suspended'];
            this.isSuspendedToCargobox = currentUser['is_suspended_to_cargobox'];
            this.isSuspendedToHugoPremium = currentUser['is_suspended_to_hugo_premium'];
            this.suspendedReason = currentUser['suspended_reason'];
            if (this.isSuspended && currentUser['suspended_reason'] === null) {
                this.suspendedReason = this.BAD_PAYER_REASON;
            }
            this.slackWebhookUrl = currentUser['slack_webhook_url'];
            this.userModulesOptions = currentUser['userRightsOptions'];
            this.userModulesOptionsSubject.next(true);
            if (this.userPreferencesService.userPreferences === undefined) {
                this.userPreferencesService.signin = false;
                this.userPreferencesService.userPreferences = (this.frontendPreferences === undefined) ?
                    this.userPreferencesService.defaultPreferences : this.frontendPreferences;
            }
            this.unitMeasurementsService.unitMeasurement = (this.userPreferencesService.userPreferences &&
                this.userPreferencesService.userPreferences.unitMeasurement)
                ? this.userPreferencesService.userPreferences.unitMeasurement : 'metric';
        }
    }

    private loginSaveData = (response, hiddenHeader) => {
        const token = response && response['token'];
        if (token) {
            this.token = token;
            this.hiddenHeader = hiddenHeader;
            this.clientDataServer = response['client_data_server'];
            this.clientDataServerId = response['client_data_server_id'];
            this.username = response['username'];
            this.frontendPreferences = response['frontendPreferences'];
            this.rememberMe = response['rememberme'];
            this.email = response['email'];
            this.userId = response['user_id'];
            this.clientId = response['client_id'];
            this.clientName = response['client_name'];
            this.phone = response['phone'];
            response['is_suspended'] = !!response['is_suspended'];
            this.isSuspended = response['is_suspended'];
            this.isSuspendedToCargobox = response['is_suspended_to_cargobox'];
            this.isSuspendedToHugoPremium = response['is_suspended_to_hugo_premium'];
            this.suspendedReason = response['suspended_reason'];
            if (this.isSuspended && response['suspended_reason'] === null) {
                this.suspendedReason = this.BAD_PAYER_REASON;
            }
            this.slackWebhookUrl = response['slack_webhook_url'];

            this.userModulesOptions = this.generateUserModuleOptions(response['userRights']);
            this.userModulesOptionsSubject.next(true);
            const rememberme = response['rememberme'];
            const angularroutes = [];
            this.token = token;
            for (const userRights of response['userRights']) {
                angularroutes.push(userRights.angularroute);
            }
            response['userRights'] = angularroutes;
            response['userRightsOptions'] = this.userModulesOptions;
            this.angularRoutes = angularroutes;
            response['createdOnLocalStorage'] = false;
            response['hiddenHeader'] = hiddenHeader;
            if (rememberme) {
                response['createdOnLocalStorage'] = true;
                localStorage.setItem('currentUser', JSON.stringify(response));
            }
            sessionStorage.setItem('currentUser', JSON.stringify(response));
            // Set userPreferences
            this.userPreferencesService.userPreferences = JSON.parse(response['frontendPreferences']);
            if (!this.userPreferencesService.userPreferences.defaultMapCenter) {
                this.userPreferencesService.userPreferences.defaultMapCenter = this.getDefaultMapCenter();
            }
            this.userPreferencesService.signin = false;
            this.userPreferencesService.token = true;
            this.setTimezoneOffset();
            return true;
        }

        return false;
    }

    private generateUserModuleOptions(data: any[]): any {
        const userModuleOptions = {};
        data.forEach(
            right => {
                if (!right.parentid && right.angularroute) {
                    userModuleOptions[right.angularroute.substring(1)] = data.filter(el => el.parentid === right.id)
                        .map(elem => elem.angularoption);
                }
            }
        );

        return userModuleOptions;
    }

    public signin(username: string, password: string, rememberme: boolean): Observable<any> {
        return this.httpClient.post(
            this.endpointsService.get('auth.authLogin'),
            {
                username: username,
                password: password,
                rememberme: rememberme,
                app: 'webapp',
                platform: 0,
                versionMajor: app.version.major,
                versionMinor: app.version.minor,
                versionPatch: app.version.patch,
            }).pipe(map((response) => this.loginSaveData(response, false)));
    }

    public autologin(token: string, hiddenHeader: boolean) {
        return this.httpClient.post(
            this.endpointsService.get('auth.authAutoLogin'),
            { token: token }).pipe(map(
                (response) => {
                    localStorage.removeItem('currentUser');
                    return this.loginSaveData(response, hiddenHeader);
                }
            ));
    }

    public saveUserSettings(fullname: string, email: string, phone: string, timezone: string, user_photo: string) {
        const currentUserJson: string = sessionStorage.getItem('currentUser');

        if (currentUserJson) {
            const currentUser = JSON.parse(currentUserJson);
            currentUser.name = fullname;
            currentUser.email = email;
            currentUser.phone = phone;
            currentUser.timezone = timezone;
            currentUser.user_photo = user_photo;

            this.phone = phone;
            this.email = email;

            sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
            if (this.rememberMe) {
                localStorage.setItem('currentUser', JSON.stringify(currentUser));
            }
        }
    }

    public set token(value: string) {
        this._token = value;
    }

    public get token(): string {
        return this.checkExpDate(this._token) ? this._token : null;
    }

    public set hiddenHeader(value: boolean) {
        this._hiddenHeader = value;
    }

    public get hiddenHeader(): boolean {
        return this._hiddenHeader;
    }

    public set clientDataServer(value: string) {
        this._clientDataServer = value;
    }

    public get clientDataServer(): string {
        return this._clientDataServer;
    }

    public set clientDataServerId(value: number) {
        this._clientDataServerId = value;
    }

    public get clientDataServerId(): number {
        return this._clientDataServerId;
    }

    public getLocale() {
        const locale: string = sessionStorage.getItem('currentLang');
        return locale ? locale : 'ro_RO';
    }

    public checkIfAnotherUserLoggedIn() {
        const currentUserLocalStorage = JSON.parse(localStorage.getItem('currentUser'));
        const currentUserSessionStorage = JSON.parse(sessionStorage.getItem('currentUser'));
        
        if (currentUserSessionStorage && currentUserSessionStorage.createdOnLocalStorage) {
            const logout = currentUserLocalStorage && (currentUserLocalStorage.token === currentUserSessionStorage.token);
            if (!logout) {
                // alert('1');
                // this.logout();
            }
        }
    }

    public apiCheckIfUserLoggedIn() {
        if (this.token) {
            const jwtHelper = new JwtHelper();
            const parsedToken = jwtHelper.decodeToken(this.token);
            this.httpClient.post(
                this.endpointsService.get('auth.verifyToken'),
                { token: parsedToken.jti }).subscribe(
                    () => noop,
                    () => {
                        // alert('2')
                        this.logout();
                        this.zone.runOutsideAngular(() => {
                            location.reload();
                        });
                    }
                );
        } else {
            // alert('3')
            this.logout();
        }
    }

    public checkMinimumVersionRequired(): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('auth.minimumVersionAccepted'), {
            versionMajor: app.version.major,
            versionMinor: app.version.minor,
            versionPatch: app.version.patch,
        });
    }

    public set username(value: string) {
        this._username = value;
    }

    public get username(): string {
        return this._username;
    }

    public set frontendPreferences(value: any) {
        const currentUserJson: string = sessionStorage.getItem('currentUser');
        if (currentUserJson) {
            const currentUser = JSON.parse(currentUserJson);
            currentUser.frontendPreferences = JSON.stringify(value);

            sessionStorage.setItem('currentUser', JSON.stringify(currentUser));

            if (this.rememberMe) {
                localStorage.setItem('currentUser', JSON.stringify(currentUser));
            }
        }
        this._frontendPreferences = value;
    }

    public get frontendPreferences(): any {
        return this._frontendPreferences;
    }

    public set rememberMe(value: boolean) {
        this._rememberMe = value;
    }

    public get rememberMe(): boolean {
        return this._rememberMe;
    }

    public set email(value: string) {
        this._email = value;
    }

    public get email(): string {
        return this._email;
    }

    public getTimezone() {
        const currentUserJson: string = sessionStorage.getItem('currentUser');

        if (currentUserJson) {
            const currentUser = JSON.parse(currentUserJson);
            return currentUser.timezone;
        }

        return null;
    }

    private checkExpDate(token) {

        if (!token) {
            return null;
        }

        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const decodedToken = JSON.parse(window.atob(base64));
        const currDate = new Date();
        const expDate = new Date(decodedToken.exp * 1000);
        return (expDate >= currDate) ? true : false;

    }

    public set userId(value: number) {
        this._userId = value;
    }

    public get userId(): number {
        return this._userId;
    }

    public set clientId(value: number) {
        this._clientId = value;
    }

    public get clientId(): number {
        return this._clientId;
    }

    public setFullName(fullname: string) {
        this.setFullNameSubject.next(fullname);
    }

    public getFullName() {
        const currentUserJson: string = sessionStorage.getItem('currentUser');

        if (currentUserJson) {
            const currentUser = JSON.parse(currentUserJson);
            return currentUser.name;
        }

        return null;
    }

    public set tachoCredentials(value: any) {
        this._tachoCredentials = value;
    }

    public get tachoCredentials(): any {
        return this._tachoCredentials;
    }

    public set angularRoutes(value: any) {
        this._angularRoutes = value;
    }

    public get angularRoutes(): any {
        return this._angularRoutes;
    }

    public set userModulesOptions(value: any) {
        this._userModulesOptions = value;
    }

    public get userModulesOptions(): any {
        return this._userModulesOptions;
    }

    public set slackWebhookUrl(value: string) {
        this._slackWebhookUrl = value;
    }

    public get slackWebhookUrl(): string {
        return this._slackWebhookUrl;
    }

    public isUserSuspended() {
        this.httpClient.get(this.endpointsService.get('user.isSuspended', [this.userId]))
            .subscribe({
                next:(data: any) => {
                    this.isSuspended = data.is_suspended;
                    this.isSuspendedToCargobox = data.is_suspended_to_cargobox;
                    this.isSuspendedToHugoPremium = data.is_suspended_to_hugo_premium;
                    this.suspendedReason = (data.reason === null) ? this.BAD_PAYER_REASON : data.reason;
                    if (this.isSuspended) {
                        if (this.angularRoutes.indexOf('/accounting') > -1 && this.suspendedReason === this.BAD_PAYER_REASON) {
                            this.router.navigate(['/accounting']);
                            return false;
                        } else {
                            this.router.navigate(['/suspended']);
                            return false;
                        }
                    }
                },
                error:(error) => {
                    if (error && error.status === 401) {
                        this.logout();
                    }
                }
            });
    }

    public checkUserModulesOptions(): void {
        this.httpClient.get(
            this.endpointsService.get('user.ownuserrights')
        ).subscribe(
            (data: any[]) => {
                if (JSON.stringify(this.generateUserModuleOptions(data)) !== JSON.stringify(this.userModulesOptions)) {
                    this.logout();
                }
            }
        );
    }

    public setTokenToExpired() {
        const jwtHelper = new JwtHelper();
        const parsedToken = jwtHelper.decodeToken(this.token);
        this.httpClient.post(
            this.endpointsService.get('auth.setTokenExpiresAt'),
            { token: parsedToken.jti }).subscribe(
                () => { }
            );
        this.token = null;
    }

    public logout() {
        if (this.token) {
            this.setTokenToExpired();
        }
        this.clientDataServer = null;
        this.clientDataServerId = null;
        this.username = null;
        this.frontendPreferences = null;
        this.rememberMe = false;
        this.email = null;
        this.userId = null;
        this.clientId = null;
        this.tachoCredentials = null;
        this.angularRoutes = [];
        this.userModulesOptions = null;
        this.clientName = null;
        this.isSuspended = false;
        this.suspendedReason = null;
        this.slackWebhookUrl = null;

        this.userPreferencesService.signin = true;

        const currentUserSessionStorage = JSON.parse(sessionStorage.getItem('currentUser'));
        sessionStorage.removeItem('currentUser');

        const currentUserJson = localStorage.getItem('currentUser');
        if (currentUserJson && (!currentUserJson.hasOwnProperty('createdOnLocalStorage')
            || (currentUserSessionStorage && currentUserSessionStorage.createdOnLocalStorage === true))) {
            localStorage.removeItem('currentUser');
        }
        this.router.navigate(['/signin']);
    }

    public sendPasswordResetEmail(loginData): Observable<any> {
        const data = {
            loginData: loginData
        };
        return this.httpClient.post(
            this.endpointsService.get('auth.passwordRecovery.sendemail'),
            data);
    }

    public resetPassword(token, newPassword) {
        const resetData = {
            token: token,
            password: newPassword
        };
        return this.httpClient.post(
            this.endpointsService.get('auth.passwordRecovery.resetPassword'),
            resetData);
    }

    public set phone(value: string) {
        this._phone = value;
    }

    public get phone(): string {
        return this._phone;
    }

    public set isSuspended(value: boolean) {
        this._isSuspended = value;
    }

    public get isSuspended(): boolean {
        return this._isSuspended;
    }

    public set isSuspendedToCargobox(value: boolean) {
        this._isSuspendedToCargobox = value;
    }

    public get isSuspendedToCargobox(): boolean {
        return this._isSuspendedToCargobox;
    }
    public set isSuspendedToHugoPremium(value: boolean) {
        this._isSuspendedToHugoPremium = value;
    }

    public get isSuspendedToHugoPremium(): boolean {
        return this._isSuspendedToHugoPremium;
    }

    public set suspendedReason(value: number) {
        this._suspendedReason = value;
    }

    public get suspendedReason(): number {
        return this._suspendedReason;
    }

    public getUserPhoto() {
        const currentUserJson: string = sessionStorage.getItem('currentUser');

        if (currentUserJson) {
            const currentUser = JSON.parse(currentUserJson);
            return currentUser.user_photo;
        }

        return null;
    }

    public setUserPhoto(photo: string) {
        this.setProfilePictureSubject.next(photo);
    }

    public getDefaultMapCenter() {
        const currentUserJson: string = sessionStorage.getItem('currentUser');

        if (currentUserJson) {
            const currentUser = JSON.parse(currentUserJson);
            return currentUser.default_map_center;

        }

        return '47.751569, 1.675063';
    }

    public getLatLngFromString(latLngStringValue: string) {

        const latLng = latLngStringValue.split(',');
        if (typeof google == undefined) {
            this.dynamicScriptLoader.load('googleApiScript').then((event) => {
                if (Array.isArray(latLng) && latLng.length === 2) {
                    return new google.maps.LatLng(Number(latLng[0].trim()), Number(latLng[1].trim()));
                }
                return new google.maps.LatLng(47.751569, 1.675063);
            })
        } else {
            if (Array.isArray(latLng) && latLng.length === 2) {
                return new google.maps.LatLng(Number(latLng[0].trim()), Number(latLng[1].trim()));
            }
            return new google.maps.LatLng(47.751569, 1.675063);
        }


    }

    public setTimezoneOffset() {
        if (this.defaultTimezoneOffset == null) {
            this.defaultTimezoneOffset = new Date().getTimezoneOffset();
        }

        const timezone = this.getTimezone();
        let applyDefaultOffset = false;
        if (timezone) {
            const dt = DateTime.DateTime.local().setZone(timezone);
            if (dt.isValid) {
                Date.prototype.getTimezoneOffset = function () {
                    return -dt.offset;
                };
            } else {
                applyDefaultOffset = true;
            }
        } else {
            applyDefaultOffset = true;
        }
        if (applyDefaultOffset) {
            const offset = this.defaultTimezoneOffset;
            Date.prototype.getTimezoneOffset = function () {
                return offset;
            };
        }
    }

    public set clientName(value: string) {
        this._clientName = value;
    }

    public get clientName(): string {
        return this._clientName;
    }

    public hasRoute(option: String) {
        return this.angularRoutes.indexOf(option) !== -1;
    }
}
