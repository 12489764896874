import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { environment } from "environments/environment";

import { SettingsService } from "app/modules/service/settings/settings.service";

@Component({
    selector: 'app-token-anaf-step1',
    templateUrl: './token-anaf-step1.component.html',
    styleUrls: ['./token-anaf-step1.component.scss']
})
export class TokenAnafStep1Component implements OnInit {

    public showError = false;
    public showSpinner = false;

    constructor(private route: ActivatedRoute, private settingsService: SettingsService, @Inject(DOCUMENT) private document: Document) { }

    /**
     * When the component is initialized, it checks if the query parameters are correct.
     * If they are correct, it calls the generateEtransportJwtToken method from the SettingsService
     * and redirects the user to the generated URL.
     * If the parameters are incorrect or the method fails, it shows an error message.
     * 
     * Componenta este deschisa intr-o fereastra noua pentru obtinerea  JWT Token-ului de la Anaf pe ruta token-anaf/step1
     * Primeste ca parametrii dsId-ul clientului, token-ul si userId-ul care incearca sa genereze JWT Token-ul, iar acestia sunt salvati in local/sessionStorage ca ultirior sa folositi in step2
     * cere de la api-dsx url-ul generat de ANAF pentru semnatura electronica, url care va apela ruta /token-anaf/step2 cu un code de succes sau cu eroare
     * acest url va fi incarcat in modalul deschis.
     */
    ngOnInit() {
        this.showSpinner = true;
        this.route.queryParamMap.subscribe({
            next: (params: ParamMap) => {
                const dsId = params.get('dsId');
                const token = params.get('token');
                const userId = params.get('userId');

                if (!dsId || !token || dsId.length === 0 || token.length ===0 || userId.length === 0) {
                    this.handleError();
                    return;
                }

                localStorage.setItem('anafData', JSON.stringify({ dsId, token, userId }));
                sessionStorage.setItem('anafData', JSON.stringify({ dsId, token, userId }));

                // 
                //  For local testing, use the following line:
                //  this.settingsService.generateEtransportJwtToken(environment.masterDs).subscribe({
                //
                const onServer = environment.production ? `https://on.cargotrack.ro/` : `https://ts1.cargotrack.ro/`;
                this.settingsService.generateEtransportJwtToken(onServer).subscribe({
                    next:(redirectUrl) => {
                        this.showSpinner = false;

                        if (redirectUrl) {
                            //
                            //  For local testing, use the following line:
                            //  this.document.location.href = `http://localhost:64215/token-anaf/step2?code=xxxxxxxxx`;
                            //
                            this.document.location.href = redirectUrl;
                            return;
                        }

                        this.showError = true;
                    },
                    error:() => this.handleError()
                });
            },
            error:() => this.handleError()
        });
    }

    public handleError(): void {
        this.showSpinner = false;
        this.showError = true;
    }
}