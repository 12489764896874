import { Component, OnInit, OnDestroy, HostListener, NgZone, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { noop, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { interval } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

import { NotificationService } from '../../service/notification.service';
import { GeneralSocket } from '../../service/general-socket.service';
import { EndpointsService } from '../../service/endpoints.service';
import { ToastService } from '../../service/toast.service';
import { AuthService } from '../../service/auth.service';
import { UserService } from '../service/user.service';

import { UserNotificationTypeInterface } from '../notification/_interface/usernotificationtype.interface';
import { UserNotificationInterface } from '../notification/_interface/usernotification.interface';
import { MenuItem } from '../model/menuitem.model';
import { ToastData } from 'app/model/toastdata';
import { faUserCog } from '@fortawesome/free-solid-svg-icons'; 

import { UpgradeRequiredComponent } from 'app/components/upgrade-required/upgrade-required.component';

const NOTIFICATION_SOUND_FILE = '/assets/sounds/notification.mp3';
const HEADER_LOGO_WIDTH = 210;
const MENU_ITEM_WIDTH = 105;
const RIGHT_MENU_WIDTH = 460;
const HTTP_UPGRADE_REQUIRED = 426;

const MENU_ITEMS_ALL = [
    new MenuItem('icon-nav-navicon01', 'icon-nav-navicon02', '/map', 'live-map', false),
    new MenuItem('icon-nav-navicon01', 'icon-nav-navicon02', '/cts', 'cts', false),
    new MenuItem('icon-nav-navicon03', 'icon-nav-navicon04', '/tt2', 'tt2', false),
    new MenuItem('icon-nav-navicon55', 'icon-nav-navicon56', '/route', 'route', false),
    new MenuItem('icon-nav-navicon53', 'icon-nav-navicon54', '/tms', 'tms', false),
    new MenuItem('icon-nav-navicon53', 'icon-nav-navicon54', '/tds', 'tds', false),
    new MenuItem('icon-nav-navicon31', 'icon-nav-navicon32', '/dispatcher', 'dispatcher', false),
    new MenuItem('icon-nav-navicon33', 'icon-nav-navicon34', '/documents', 'documents', false),
    new MenuItem('icon-nav-navicon07', 'icon-nav-navicon08', '/reports', 'reports', false),
    new MenuItem('icon-nav-navicon35', 'icon-nav-navicon36', '/roadtax', 'roadtax', false),
    new MenuItem('icon-nav-navicon45', 'icon-nav-navicon46', '/sharelink', 'sharelink', false),
    new MenuItem('icon-nav-navicon37', 'icon-nav-navicon38', '/accounting', 'accounting', false),
    new MenuItem('icon-nav-navicon47', 'icon-nav-navicon48', '/vehiclebinding', 'vehiclebinding', false),
    new MenuItem('icon-nav-navicon27', 'icon-nav-navicon28', '/drivers', 'drivers', false),
    new MenuItem('icon-nav-navicon23', 'icon-nav-navicon24', '/users', 'users', false),
    new MenuItem('icon-nav-navicon43', 'icon-nav-navicon44', '/tacho', 'tacho', false),
    new MenuItem('icon-nav-navicon11', 'icon-nav-navicon12', '/rer', 'rer', false),
    new MenuItem('icon-nav-navicon13', 'icon-nav-navicon14', '/pgs', 'pgs', false),
    // new MenuItem('ctsicon-topnav-contacts-off', 'ctsicon-topnav-contacts-on', '/contacts-agenda', 'contacts-agenda', false),
    new MenuItem('icon-nav-navicon15', 'icon-nav-navicon16', '/contacts-agenda', 'contacts-agenda', false),
    new MenuItem('icon-nav-navicon39', 'icon-nav-navicon40', '/e-transport', 'e-transport', false),
];

const MENU_ITEMS_MOBILE = [
    new MenuItem('icon-nav-navicon01', 'icon-nav-navicon02', '/map', 'live-map', false),
    new MenuItem('icon-nav-navicon01', 'icon-nav-navicon02', '/cts', 'cts', false),
    new MenuItem('icon-nav-navicon03', 'icon-nav-navicon04', '/tt2', 'tt2', false),
    new MenuItem('icon-nav-navicon53', 'icon-nav-navicon54', '/tms', 'tms', false),
    new MenuItem('icon-nav-navicon35', 'icon-nav-navicon36', '/roadtax', 'roadtax', false),
    new MenuItem('icon-nav-navicon45', 'icon-nav-navicon46', '/sharelink', 'sharelink', false),
    new MenuItem('icon-nav-navicon37', 'icon-nav-navicon38', '/accounting', 'accounting', false),
];

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Input() headerActive;

    private _subscriptions: Subscription[] = [];
    private checkLoggedInPeriod = 3000;
    private checkApiLoggedInPeriod = 600000;
    private checkMinimumVersionAcceptedPeriod = 86400000;
    private isActiveCheckMinimumVersionAccepted: boolean;

    // public userNotifications$: Observable<UserNotificationInterface[]>;
    public userNotificationTypes: any;
    public menuitems: MenuItem[];
    public menudropdown: MenuItem[] = [];
    public primarymenu: MenuItem[] = [];
    public showmenudropdown: boolean;
    public usermenudropdown: boolean;
    public menu: MenuItem[] = [];
    public fullname: string;
    public user_photo: string;
    public notifHref: boolean;
    public isMobile = false;
    public firstMenuWidth: number;
    public logoSrc = '/assets/project/' + environment.projectName + '/images/logo-small.png';
    public hasNotificationsRights: boolean;
    public hasSettingsRights: boolean;
    public hasModulesRights: boolean;
    public faUserCog = faUserCog;

    constructor(
        private authService: AuthService,
        private router: Router,
        private httpClient: HttpClient,
        private userService: UserService,
        private generalSocketService: GeneralSocket,
        private notificationService: NotificationService,
        private zone: NgZone,
        private endpointsService: EndpointsService,
        public snackBar: MatSnackBar,
        private toastService: ToastService,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.authService.isUserSuspended();
        this.authService.checkUserModulesOptions();
        this.notifHref = this.router.url.includes('/notification');
        // this.userNotifications$ = this.notificationService.headerUserNotificationsList();

        if (window.innerWidth < 768) { // 768px portrait
            this.isMobile = true;
        }

        this.hasSettingsRights = (this.authService.angularRoutes.indexOf('/settings') !== -1) ? true : false
        this.hasModulesRights = (this.authService.angularRoutes.indexOf('/packages') !== -1) ? true : false
        this.hasNotificationsRights = (this.authService.angularRoutes.indexOf('/notification') !== -1) ? true : false;

        this.fullname = this.authService.getFullName();
        this.user_photo = this.authService.getUserPhoto();

        this._subscriptions.push(
            this.notificationService.userNotificationsTypesList$.subscribe(
                (userNotificationTypes: UserNotificationTypeInterface[]) => {
                    this.userNotificationTypes = {};
                    userNotificationTypes.forEach((el: UserNotificationTypeInterface) => this.userNotificationTypes[el.id] = el);

                    if (Object.keys(this.userNotificationTypes).length > 0) {
                        //  se porneste serviciul de websocket in scope general
                        //  trebuie sa porneasca dupa ce primim tipurile de user notifications de la server in caz
                        //  ca vin prin websocket notificari pentru utilizator
                        this.generalSocketService.init();
                    }
                }
            ),
            //  Make notification header icon highlighted
            this.router.events.subscribe(() => this.notifHref = this.router.url.includes('/notification')),
            this.authService.setFullNameSubject.subscribe(response => this.fullname = response),
            this.authService.setProfilePictureSubject.subscribe(response => this.user_photo = response),
            this.authService.requiresAttentionSubject.subscribe(
                (route) => {
                    this.menuitems.map(
                        (menuItem) => {
                            if (menuItem.route === route) {
                                menuItem.requiresAttention = false;
                            }
                        }
                    );
                }
            ),
            this.authService.sendMessageSubject.subscribe(
                (data) => {
                    if (this.router.url !== '/dispatcher') {
                        if (data.to_user_id === this.authService.userId) {
                            this.showMessageAlert(data);
                        }
                        const index = this.menuitems.findIndex(menu => menu.route === '/dispatcher');
                        if (index !== -1) {
                            this.menuitems[index].requiresAttention = true;
                        }
                    }
                }
            )
        );

        if (this.hasNotificationsRights) {
            this._subscriptions.push(
                this.notificationService.showSnackMessageSubject.subscribe(
                    (notification: UserNotificationInterface) => this.showSnackbarNotifications(notification)
                )
            );
        }

        this.getMenu();
        this.menu = this.menuitems.filter(menuitem => this.authService.angularRoutes.indexOf(menuitem['route']) !== -1);
        this.rearrangeMenuItems();

        // Check if another user is logged in another browser window
        this._subscriptions.push(interval(this.checkLoggedInPeriod).subscribe(
            () => this.authService.checkIfAnotherUserLoggedIn()
        ));

        // Api call Check if another user is logged in
        this.authService.apiCheckIfUserLoggedIn();
        this._subscriptions.push(interval(this.checkApiLoggedInPeriod).subscribe(
            () => this.authService.apiCheckIfUserLoggedIn()
        ));

        this._subscriptions.push(interval(this.checkMinimumVersionAcceptedPeriod).subscribe(
            () => this.authService.checkMinimumVersionRequired().subscribe(
                () => noop,
                (error) => {
                    if (error.status === HTTP_UPGRADE_REQUIRED && !this.isActiveCheckMinimumVersionAccepted) {
                        this.isActiveCheckMinimumVersionAccepted = true;
                        const dialogRef = this.dialog.open(
                            UpgradeRequiredComponent, { maxWidth: '800px', panelClass: 'custom-dialog-container', disableClose: true }
                        );
                        dialogRef.afterClosed().subscribe(
                            response => {
                                if (response) {
                                    location.reload();
                                    return false;
                                }
                                this.isActiveCheckMinimumVersionAccepted = false;
                            }
                        );
                    }
                }
            )
        ));
    }

    @HostListener('window:resize') onResize() {
        this.isMobile = window.innerWidth < 768;
        this.getMenu();
        this.menu = this.menuitems.filter(menuitem => this.authService.angularRoutes.indexOf(menuitem['route']) !== -1);
        this.rearrangeMenuItems();
    }

    public getMenu() {
        if (this.isMobile) {
            this.menuitems = MENU_ITEMS_MOBILE;
        } else {
            this.menuitems = MENU_ITEMS_ALL;
        }
    }

    public rearrangeMenuItems() {
        const innerWidth = window.innerWidth;
        this.firstMenuWidth = innerWidth - RIGHT_MENU_WIDTH;
        const itemsCount = Math.ceil((this.firstMenuWidth - HEADER_LOGO_WIDTH) / MENU_ITEM_WIDTH) - 1;

        if (this.menu.length > itemsCount && innerWidth >= 768) {
            this.showmenudropdown = true;
            this.primarymenu = this.menu.slice(0, itemsCount);
            this.menudropdown = this.menu.slice(itemsCount);
        } else {
            this.showmenudropdown = false;
            this.primarymenu = this.menu;
        }

        if (innerWidth < 768) {
            this.usermenudropdown = true;
        } else {
            this.usermenudropdown = false;
        }
    }

    ngOnDestroy() {
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public onLogout() {
        this.generalSocketService.onClose();
        this.authService.logout();
        setTimeout(() => { this.zone.runOutsideAngular(() => location.reload()); }, 1000);
    }

    public onToggleHeader() {
        if(this.headerActive) {
            this.headerActive = false;
            this.userService.toggleHeader.next(false);
        } else {
            this.headerActive = true;
            this.userService.toggleHeader.next(true);
        }

    }

    private showMessageAlert(data: any) {
        const snackBarConfig = new MatSnackBarConfig();
        snackBarConfig.verticalPosition = 'top';
        snackBarConfig.horizontalPosition = 'end';
        snackBarConfig.duration = 3000;
        snackBarConfig.panelClass = ['snackbar-warning'];

        const snackBarRef = this.snackBar.open(data.message, '', snackBarConfig);

        snackBarRef.onAction().subscribe(() => {
            this.notificationService.decreaseUnreadNotificationsNumber();
        });
    }

    private showSnackbarNotifications(notification: UserNotificationInterface) {
        const dataMessage: ToastData = { notification: notification, type: this.userNotificationTypes };
        if (notification.priority > 3) {
            const audioNotification = new Audio(NOTIFICATION_SOUND_FILE);
            audioNotification.play();
        }

        this.toastService.show(dataMessage);
    }

    public logClick(route: string) {
        this.httpClient.post(this.endpointsService.get('user.useraccesslog'), { route: route }).subscribe(() => { });
    }
}
