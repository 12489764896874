import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject, forkJoin, noop, EMPTY } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as moment from 'moment';

import { EndpointsService } from 'app/service/endpoints.service';
import { AuthService } from '../../../service/auth.service';

import { Device } from 'app/modules/model/settings/device.model';
import { RerClientAddress } from '../_model/rerclientaddress.model';
import { RerClient } from '../_model/rerclient.model';
import { RerClientAssociation } from '../_model/rerclientassociations.model';
import { RerRfidTag } from '../_model/rerrfidtag.model';
import { RerRfidTagReading } from '../_model/rerrfidtagreading.model';
import { RerEmployee } from '../_model/reremployee.model';
import { NotificationComponent } from '../../../shared/notification/notification.component';
import { RerSettings } from '../_model/rersettings.model';
import { RerWasteType } from '../_model/rerwastetype.model';
import { RerBinType } from '../_model/rerbintype.model';
import { CollectionByWeightPlanningAddress, RerPlanning } from '../_model/rerplanning.model';
import { RerBin } from '../_model/rerbin.model';
import { RerClientChanges } from '../_model/rerclientchanges.model';
import { RerRfidTagAllocation } from '../_model/rerrfidtagallocation.model';
import { RerArea } from '../_model/rerarea.model';
import { RerIncident } from '../_model/rerincident.model';
import { RerUserIncident } from '../_model/reruserincident.model';
import { RerClientIncident } from '../_model/rerclientincident.model';
import { RerClientAddressIncident } from '../_model/rerclientaddressincident.model';
import { RerIncidentLog } from '../_model/rerincidentlog.model';
import { RerBinOwnership } from './../_model/rerbinownership';
import { RerEmailTemplate } from '../_model/reremailtemplate.model';
import { RerBinSetupImport } from '../_model/rerbinsetupimport.model';
import { RerBinSetupDocument } from '../_model/rerbinsetupdocument.model';
import { RerBinSetupDocumentAssociation } from '../_model/rerbinsetupdocumentassociations.model';
import { RerCity } from '../street-geozones/models/rer-city.model';
import { RerStreet } from '../street-geozones/models/rer-street.model';
import { RerRoute } from '../route/models/rer-route.model';

@Injectable({
    providedIn: 'root',
})
export class RerService {
    private rerDevicelListSubject = new BehaviorSubject<Device[]>([]);
    private rerClientListSubject = new BehaviorSubject<RerClient[]>([]);
    private rerBinSetupImportListSubject = new BehaviorSubject<RerBinSetupImport[]>([]);
    private rerRfidTagListSubject = new BehaviorSubject<RerRfidTag[]>([]);
    private rerBinListSubject = new BehaviorSubject<RerBin[]>([]);
    private rerBinSetupDocumentListSubject = new BehaviorSubject<RerBinSetupDocument[]>([]);
    private rerClientAssociationsListSubject = new BehaviorSubject<RerClientAssociation[]>([]);
    private rerBinSetupDocumentAssociationsListSubject = new BehaviorSubject<RerBinSetupDocumentAssociation[]>([]);
    private cityFilterListSubject = new BehaviorSubject<string[]>([]);
    private devisionFilterListSubject = new BehaviorSubject<string[]>([]);
    private typeFilterListSubject = new BehaviorSubject<string[]>([]);
    private streetFilterListSubject = new BehaviorSubject<string[]>([]);
    private binOwnershipFilteredListSubject = new BehaviorSubject<string[]>([]);
    private unallocatedRerBinsListSubject = new BehaviorSubject<RerBin[]>([]);
    private rerEmployeeListSubject = new BehaviorSubject<RerEmployee[]>([]);
    private rerWasteTypeListSubject = new BehaviorSubject<RerWasteType[]>([]);
    private rerBinOwnershipListSubject = new BehaviorSubject<RerBinOwnership[]>([]);
    private rerBinTypeListSubject = new BehaviorSubject<RerBinType[]>([]);
    public intervalForPlanningsChangedSubject = new BehaviorSubject<string>('');
    public rerClientAddressListSubject = new BehaviorSubject<RerClientAddress[]>([]);
    public rerBinListChangedSubject = new Subject<{rerBin: RerBin[], clientId: number}>();
    public rerClientEditableSubject = new Subject<boolean>();
    public rerClientChangedSubject = new Subject<boolean>();
    public rerMapVehicleLocationSubject = new BehaviorSubject<boolean>(false);
    public rerFilterByInvoiceableSubject = new BehaviorSubject<boolean>(false);
    public rerPlanningListSubject = new BehaviorSubject<RerPlanning[]>([]);
    public planningsDetailsChangedSubject = new BehaviorSubject<RerPlanning[]>([]);
    public planningsRfidReadingsChangedSubject = new BehaviorSubject<any[]>([]);
    private rerAreaListSubject = new BehaviorSubject<RerArea[]>([]);
    public rerIncidentListSubject = new BehaviorSubject<RerIncident[]>([]);
    public incidentsDetailsChangedSubject = new BehaviorSubject<RerIncident[]>([]);
    public rerUserIncidentsListSubject = new BehaviorSubject<RerUserIncident[]>([]);
    public rerClientIncidentsListSubject = new BehaviorSubject<RerClientIncident[]>([]);
    public rerClientAddressIncidentsListSubject = new BehaviorSubject<RerClientAddressIncident[]>([]);
    public rerEmailTemplateListSubject = new BehaviorSubject<RerEmailTemplate[]>([]);
    public rerBinSetupDocumentsSubject = new BehaviorSubject<RerBinSetupDocument[]>([]);
    public rerClientBinSetupDocumentListSubject = new BehaviorSubject<RerBinSetupDocument[]>([]);

    public deviceList$: Observable<Device[]> = this.rerDevicelListSubject.asObservable();
    public clientList$: Observable<RerClient[]> = this.rerClientListSubject.asObservable();
    public rerBinSetupImportList$: Observable<RerBinSetupImport[]> = this.rerBinSetupImportListSubject.asObservable();
    public rfidTagList$: Observable<RerRfidTag[]> = this.rerRfidTagListSubject.asObservable();
    public rerBinList$: Observable<RerBin[]> = this.rerBinListSubject.asObservable();
    public rerBinSetupDocumentList$: Observable<RerBinSetupDocument[]> = this.rerBinSetupDocumentListSubject.asObservable();
    public clientAddressList$: Observable<RerClientAddress[]> = this.rerClientAddressListSubject.asObservable();
    public clientUsersList$: Observable<RerClientAssociation[]> = this.rerClientAssociationsListSubject.asObservable();
    public clientUsersBinSetupDocumentList$: Observable<RerBinSetupDocumentAssociation[]> = this.rerBinSetupDocumentAssociationsListSubject.asObservable();
    public cityFilterList$: Observable<string[]> = this.cityFilterListSubject.asObservable();
    public devisionFilterList$: Observable<string[]> = this.devisionFilterListSubject.asObservable();
    public typeFilterList$: Observable<string[]> = this.typeFilterListSubject.asObservable();
    public streetFilterList$: Observable<string[]> = this.streetFilterListSubject.asObservable();
    public binOwnershipFilteredList$: Observable<string[]> = this.binOwnershipFilteredListSubject.asObservable();
    public unallocatedObjectList$: Observable<RerBin[]> = this.unallocatedRerBinsListSubject.asObservable();
    public employeeList$: Observable<RerEmployee[]> = this.rerEmployeeListSubject.asObservable();
    public rerPlanningList$: Observable<RerPlanning[]> = this.rerPlanningListSubject.asObservable();
    public wasteTypeList$: Observable<RerWasteType[]> = this.rerWasteTypeListSubject.asObservable();
    public binOwnershipList$: Observable<RerBinOwnership[]> = this.rerBinOwnershipListSubject.asObservable();
    public binTypeList$: Observable<RerBinType[]> = this.rerBinTypeListSubject.asObservable();
    public areaList$: Observable<RerArea[]> = this.rerAreaListSubject.asObservable();
    public incidentList$: Observable<RerIncident[]> = this.rerIncidentListSubject.asObservable();
    public clientUsersIncidentList$: Observable<RerUserIncident[]> = this.rerUserIncidentsListSubject.asObservable();
    public clientIncidentList$: Observable<RerClientIncident[]> = this.rerClientIncidentsListSubject.asObservable();
    public clientAddressIncidentList$: Observable<RerClientAddressIncident[]> = this.rerClientAddressIncidentsListSubject.asObservable();
    public static rerDashboardListSubject = new BehaviorSubject<RerPlanning[]>([]);
    public static rerDashboardList$: Observable<RerPlanning[]> = RerService.rerDashboardListSubject.asObservable();
    public emailTemplateList$: Observable<RerEmailTemplate[]> = this.rerEmailTemplateListSubject.asObservable();
    public rerBinSetuptDocumentList$: Observable<RerBinSetupDocument[]> = this.rerBinSetupDocumentsSubject.asObservable();
    public rerClientBinSetupDocumentList$: Observable<RerBinSetupDocument[]> = this.rerClientBinSetupDocumentListSubject.asObservable();

    private loadStreetGeozones = new BehaviorSubject<RerCity[] | undefined>(undefined);
    public streetGeozones$: Observable<RerCity[] | undefined> = this.loadStreetGeozones.asObservable();
    private loadRoutes = new BehaviorSubject<RerRoute[] | undefined>(undefined);
    public route$: Observable<RerRoute[] | undefined> = this.loadRoutes.asObservable();

    public smtpTestSubject = new Subject<any>();
    public spinnerSubject: Subject<boolean> = new Subject();
    public employeeType = { 1: 'driver', 2: 'operator' };

    //Rer sub-modules
    static readonly ROUTE_RER_DASHBOARD = 'rer-dashboard';
    static readonly ROUTE_RER_STREET_GEOZONE = 'rer-street-geozone';
    static readonly ROUTE_RER_ROUTE = 'rer-route';
    static readonly ROUTE_RER_PLANING = 'rer-planning';
    static readonly ROUTE_RER_ROUTE_PLANNING_SEARCH = 'rer-route-planning-search';
    static readonly ROUTE_RER_CLIENTS = 'rer-client';
    static readonly ROUTE_RER_OBJECTS = 'rer-object';
    static readonly ROUTE_RER_OBJECTS_ALLOCATION = 'objects-association';
    static readonly ROUTE_RER_BIN_SETUP_DOCUMENTS_ALLOCATION = 'rer-bin-setup-document-association';
    static readonly ROUTE_RER_DATA_IMPORT = 'data-import-rer';
    static readonly ROUTE_RER_DATA_EXPORT = 'data-export-rer';
    static readonly ROUTE_RER_RFID_MANAGEMENT = 'rfid-management';
    static readonly ROUTE_RER_UNALLOCATED_OBJECTS = 'unallocated-object';
    static readonly ROUTE_RER_EMPLOYEE = 'rer-employee';
    static readonly ROUTE_RER_SETTINGS = 'rer-settings';
    static readonly ROUTE_RER_INCIDENT = 'rer-incident';
    static readonly ROUTE_POWER_BI_REPORT = 'rer-power-bi-report';
    static readonly ROUTE_RER_COLLECTION_BY_WEIGHT = 'rer-collection-by-weight';
    static readonly ROUTE_RER_COLLECTION_DECISIONS_BY_WEIGHT = 'collection-decisions-by-weight';
    static readonly ROUTE_RER_REPORTS_SENT_AUTOMATICALLY = 'report-sent-automatically';
    static readonly ROUTE_RER_GENERAL_REPORTS = 'general-reports';
    static readonly ROUTE_RER_UNLOADING_AREAS = 'unloading-areas';
    static readonly ROUTE_RER_OPERATION_OF_WEIGHING_RECEIPTS = 'operation-of-weighing-receipts';
    static readonly ROUTE_RER_VERBAL_PROCESS = 'rer-verbal-process';

    //Rer available settings
    public readonly RER_SETTINGS_DEVICE_SPEED = 'device_max_speed';
    public readonly RER_SETTINGS_VALID_UNTIL = 'max_valid_minute_limit';
    public readonly RER_SETTINGS_INVALID_FROM = 'min_invalid_minute_limit';
    public readonly RER_SETTINGS_PHONE_1 = 'phone1';
    public readonly RER_SETTINGS_PHONE_2 = 'phone2';
    public readonly RER_SETTINGS_PHONE_3 = 'phone3';
    public readonly RER_SETTINGS_PHONE_4 = 'phone4';
    public readonly RER_SETTINGS_PHONE_5 = 'phone5';
    public readonly RER_SETTINGS_POWER_BI_URL = 'power_bi_url';
    public readonly RER_SETTINGS_SMTP_EMAIL_ADDRESS = 'smtp_email_address';
    public readonly RER_SETTINGS_SMTP_EMAIL_PASSWORD = 'smtp_email_password';
    public readonly RER_SETTINGS_SMTP_HOST = 'smtp_host';
    public readonly RER_SETTINGS_SMTP_PORT = 'smtp_port';
    public readonly RER_SETTINGS_SMTP_VERIFIED = 'smtp_verified';
    public readonly RER_SETTINGS_SMTP_FAILED_COUNTER = 'smtp_failed_counter';
    public readonly RER_BIN_SETUP_DOCUMENT_LIMIT = 100;

    //Websocket actions && entities
    private readonly ACTION_REALOAD_DASHBOARD = 'reload_dashboard_data';
    private readonly ACTION_UPDATE_PLANNNING_ANTENNA = 'update_planning_on_dashboard';

    private readonly UPDATE_PLANNNINGS_READINGS_ENTITY = 'rer_planning';

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
        private endpointsService: EndpointsService,
        private actionDialog: MatDialog,
        private translateService: TranslateService
    ) {
    }

    public hasRight(option: String) {
        return this.authService.userModulesOptions['rer']
            .find((item: string) => item === option);
    }

    public getBinAndWasteTypeData() {
        const myList = [
            this.httpClient.get<any[]>(this.endpointsService.get('rer.getWasteTypeList')),
            this.httpClient.get<any[]>(this.endpointsService.get('rer.getBinTypeList')),
            this.httpClient.get<any[]>(this.endpointsService.get('rer.getRerBinOwnershipList'))
        ];

        forkJoin(myList).subscribe(
            (response) => {
                this.rerWasteTypeListSubject.next(response[0]);
                this.rerBinTypeListSubject.next(response[1]);
                this.rerBinOwnershipListSubject.next(response[2]);
            },
            () => noop,
            () => this.spinnerSubject.next(false)
        );
    }

    public getDeviceList() {
        this.httpClient.get<any[]>(this.endpointsService.get('device.list')).subscribe({
           next:(data: Device[]) => this.rerDevicelListSubject.next(data)
        });
    }

    public getAllDatas() {
        let periodEnd = new Date(new Date().getTime()).toISOString().slice(0, 10);
        let periodBegin = new Date(new Date().getTime()).toISOString().slice(0, 10);
        // let periodEnd = new Date(new Date().getTime() - (24 * 60 * 60 * 1000)).toISOString().slice(0, 10);
        // let periodBegin = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString().slice(0, 10);
        const myList = [
            this.httpClient.get<any[]>(this.endpointsService.get('device.list')),
            this.httpClient.get<any[]>(this.endpointsService.get('rer.getWasteTypeList')),
            this.httpClient.get<any[]>(this.endpointsService.get('rer.getBinTypeList')),
            this.httpClient.get(this.endpointsService.get('rer.getFilterData')),
        ];

        const hasRerClientAssociations = this.hasRight(AuthService.USER_RIGHT_RER_CLIENT_ASSOCIATION);
        if (hasRerClientAssociations) {
            myList.push(
                this.httpClient.get<any[]>(this.endpointsService.get('user.getUserListWithObjects')).pipe(map(
                    data => this.prepareRerClientAssociations(data)
                )
                ));
        }

        const hasRerBinSetupDocumentAssociations = this.hasRight(AuthService.USER_RIGHT_RER_CLIENT_BIN_SETUP_DOC_ASSOCIATION);
        if (hasRerBinSetupDocumentAssociations) {
            myList.push(
                this.httpClient.get<any[]>(this.endpointsService.get('user.getUserListWithSetupDocument')).pipe(map(
                    data => this.prepareSetupDocumentAssociations(data)
                )
            ));
        }

        const hasRfidTagsManagement = this.hasRight(AuthService.USER_RIGHT_RER_RFID_MANAGEMENT)
        if (hasRfidTagsManagement) {
            myList.push(
                this.httpClient.get<any[]>(this.endpointsService.get('rfidtag.listAvailable'))
            );
        }

        const hasEmployeeAccess = this.hasRight(AuthService.USER_RIGHT_RER_EMPLOYEE);
        if (hasEmployeeAccess) {
            myList.push(
                this.httpClient.get<any[]>(this.endpointsService.get('rer.getEmployees'))
            );
        }
        const hasIncidentAccess = this.hasRight(AuthService.USER_RIGHT_RER_INCIDENT);
        if (hasIncidentAccess) {
            let params = new HttpParams().set("period_begin", String(''))
                .set("period_end", String(''));
            const httpOptions = {
                params: params
            };
            myList.push(
                this.httpClient.get<any[]>(this.endpointsService.get('rer.getIncidents'), httpOptions)
            );
        }

        const hasRerPlanningAccess = this.hasRight(AuthService.USER_RIGHT_RER_PLANNING);
        const hasRerDashboarAccess = this.hasRight(AuthService.USER_RIGHT_RER_DASHBOARD);
        if (hasRerDashboarAccess || hasRerPlanningAccess) {
            myList.push(
                this.httpClient.get<any[]>(this.endpointsService.get('rer.getPlannings', [periodBegin, periodEnd])).pipe(map((response: any) => {
                    return response.map(x => {
                        let addresses = [];
                        if (x.rer_collection_by_weight_planning_address) {
                            x.rer_collection_by_weight_planning_address.forEach(el => addresses.push(new CollectionByWeightPlanningAddress(el.rer_client_address_id, el.rer_client_id, el.rer_client_address.name, el.rer_client.name, el.minimum_notification_quantity)));
                        }
                        x.addresses = addresses;
                        return x;
                    })
                }))
            );
        }
        const hasRerBinOwnershipAccess = this.hasRight(AuthService.USER_RIGHT_RER_BIN_OWNERSHIP_SETTINGS);
        if (hasRerBinOwnershipAccess) {
            myList.push(
                this.httpClient.get<any[]>(this.endpointsService.get('rer.getRerBinOwnershipList'))
            );
        }

        forkJoin(myList).subscribe({
            next:(response: any) => {
                let responsePostion = 0
                this.rerDevicelListSubject.next(response[responsePostion++]);
                this.rerWasteTypeListSubject.next(response[responsePostion++]);
                this.rerBinTypeListSubject.next(response[responsePostion++]);

                //filtered data response - DO NOT INCREMENT until next user right
                if (response[responsePostion].cityList && response[responsePostion].cityList.length > 0) {
                    this.cityFilterListSubject.next(response[responsePostion].cityList);
                }
                if (response[responsePostion].divisionList && response[responsePostion].divisionList.length > 0) {
                    this.devisionFilterListSubject.next(response[responsePostion].divisionList);
                }
                if (response[responsePostion].typeList && response[responsePostion].typeList.length > 0) {
                    this.typeFilterListSubject.next(response[responsePostion].typeList);
                }
                if (response[responsePostion].streetList && response[responsePostion].streetList.length > 0) {
                    this.streetFilterListSubject.next(response[responsePostion].streetList);
                }
                if (response[responsePostion].binOwnership && response[responsePostion].binOwnership.length > 0) {
                    this.binOwnershipFilteredListSubject.next(response[responsePostion].binOwnership);
                }

                if (hasRerClientAssociations) {
                    if (response[++responsePostion] && response[responsePostion].length > 0) {
                        this.rerClientAssociationsListSubject.next(response[responsePostion]);
                    }
                }
                if (hasRerBinSetupDocumentAssociations) {
                    if (response[++responsePostion] && response[responsePostion].length > 0) {
                        this.rerBinSetupDocumentAssociationsListSubject.next(response[responsePostion]);
                    }
                }
                if (hasRfidTagsManagement) {
                    if (response[++responsePostion] && response[responsePostion].length > 0) {
                        this.rerRfidTagListSubject.next(response[responsePostion]);
                    }
                }
                if (hasEmployeeAccess) {
                    if (response[++responsePostion] && response[responsePostion].length > 0) {
                        this.rerEmployeeListSubject.next(response[responsePostion])
                    }
                }

                if (hasIncidentAccess) {
                    if (response[++responsePostion] && response[responsePostion].length > 0) {
                        this.rerIncidentListSubject.next(response[responsePostion])
                    }
                }
                if (hasRerDashboarAccess || hasRerPlanningAccess) {
                    if (response[++responsePostion] && response[responsePostion].length > 0) {
                        this.rerPlanningListSubject.next(response[responsePostion]);
                    }
                }
                if (hasRerBinOwnershipAccess) {
                    if (response[++responsePostion] && response[responsePostion].length > 0) {
                        this.rerBinOwnershipListSubject.next(response[responsePostion++]);
                    }
                }
            },
            error:() => noop,
            complete:() => this.spinnerSubject.next(false)
        });
    }

    private prepareRerClientAssociations(data: any): RerClientAssociation[] {
        const users = [];
        Object.keys(data).forEach(key =>
            users.push(
                new RerClientAssociation(
                    data[key].id,
                    data[key].name,
                    data[key].rer_bins.map(
                        (obj: any) => new RerBin(
                            obj.id,
                            null,
                            null,
                            obj.address,
                            obj.city,
                            obj.waste_type,
                            obj.rer_waste_type_id,
                            null,
                            obj.status,
                            obj.rer_rfid,
                            obj.rer_rfid_tag_id,
                            obj.bin_type,
                            obj.rer_bin_type_id,
                            null,
                            obj.rer_client_name,
                            null,
                            obj.division,
                            obj.street,
                            obj.street_number
                        )
                    )
                )
            ));
        return users;
    }

    public downloadVerbalProcess(binId){
        return this.httpClient.get<any>(this.endpointsService.get('rerbin.downloadVerbalProcess', [binId]));
    }

    public getRerClientChanges(rerClientId: number): Observable<RerClientChanges[]> {
        return this.httpClient.get<RerClientChanges[]>(this.endpointsService.get('rerclient.getChanges', [rerClientId]));
    }

    public getRerRfidReadingVideo(device_id: number, date_time: string, ipCameraId: number): Observable<any> {
        let params = new HttpParams().set("device_id", String(device_id)).set("date_time", date_time).set("ip_camera_id", String(ipCameraId));
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            observe: 'response' as 'body',
            responseType: 'blob' as 'blob',
            params: params
        };
        return this.httpClient.get(this.endpointsService.get('ipcamera.getVideoByDatetime'), httpOptions);
    }

    public getRerRfidReadingImage(device_id: number, date_time: string, ipCameraId: number): Observable<any> {
        let params = new HttpParams().set("device_id", String(device_id)).set("date_time", date_time).set("ip_camera_id", String(ipCameraId));
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            observe: 'response' as 'body',
            responseType: 'blob' as 'blob',
            params: params
        };
        return this.httpClient.get(this.endpointsService.get('ipcamera.getImageByDatetime'), httpOptions);
    }

    public getNextPrevVideo(device_id: number, date_time: string, ipCameraId: number, skip: number, direction: number): Observable<any> {
        let params = new HttpParams().set("device_id", String(device_id)).set("date_time", date_time).set("ip_camera_id", String(ipCameraId))
            .set("skip", String(skip)).set('direction', String(direction));
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            observe: 'response' as 'body',
            responseType: 'blob' as 'blob',
            params: params
        };
        return this.httpClient.get(this.endpointsService.get('ipcamera.getNextPrevVideo'), httpOptions);
    }

    public getNextPrevImage(device_id: number, date_time: string, ipCameraId: number, skip: number, direction: number): Observable<any> {
        let params = new HttpParams().set("device_id", String(device_id)).set("date_time", date_time).set("ip_camera_id", String(ipCameraId))
            .set("skip", String(skip)).set('direction', String(direction));
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }),
            observe: 'response' as 'body',
            responseType: 'blob' as 'blob',
            params: params
        };
        return this.httpClient.get(this.endpointsService.get('ipcamera.getNextPrevImage'), httpOptions);
    }

    public getRerClients(filterData: { cityList: string, divisionList: string, typeList: string, streetList: string, activeOnly: boolean }): Observable<{ rerClientList: any[] }> {
        return this.httpClient.post<{ rerClientList: any[] }>(
            this.endpointsService.get('rerclient.getFilteredList'),
            filterData
        ).pipe(
            tap((data: { rerClientList: any[] }) => {
                const rawClients = data.rerClientList;
                if (rawClients) {
                    const clients = [];
                    rawClients.forEach(cl => clients.push(
                        new RerClient(
                            cl.id,
                            cl.code,
                            cl.type,
                            cl.name,
                            cl.email,
                            cl.default_address,
                            cl.division,
                            cl.default_city,
                            cl.street,
                            cl.street_number,
                            cl.phone,
                            cl.is_fixed_invoicing,
                            cl.status,
                            cl.delegate_name,
                            cl.description,
                            cl.object_types_at_default_address
                        )
                    ));

                    this.rerClientListSubject.next(clients);
                }
            })
        );
    }

    public rerBins(filterData: {
        cityList: string, divisionList: string, typeList: string,
        streetList: string, binOwnershipList: string, activeOnly: boolean
    }): Observable<{ rerBinsList: any[] }> {
        return this.httpClient.post<{ rerBinsList: any[] }>(this.endpointsService.get('rerbin.list'), filterData)
            .pipe(tap((data: { rerBinsList: any[] }) => {
                const rawBins = data.rerBinsList;
                if (rawBins) {
                    this.rerBinListSubject.next(rawBins);
                }
            })
        );
    }

    public rerBinSetupDocuments(
        filterData: { cityList: string, divisionList: string, typeList: string, streetList: string, status: any, activeOnly: boolean, limit: number, offset: number }, isFirstLoad: boolean
    ): Observable<{ rerBinSetupDocumentList: any[] }> {
        return this.httpClient.post<{ rerBinSetupDocumentList: any[] }>(this.endpointsService.get('rerbinsetupdocument.list'), filterData)
            .pipe(tap((data: { rerBinSetupDocumentList: any[] }) => {
                const rawBinSetupDoct = data.rerBinSetupDocumentList;
                if (rawBinSetupDoct) {
                    const currentValue = this.rerBinSetupDocumentListSubject.getValue();
                    const data = isFirstLoad ? rawBinSetupDoct : [...currentValue, ...rawBinSetupDoct];
                    this.rerBinSetupDocumentListSubject.next(data);
                }
            })
        );
    }

    public searchRerBinSetupDocuments(searchValue: string): Observable<{ rerBinSetupDocumentList: any[] }> {
        return this.httpClient.post<{ rerBinSetupDocumentList: any[] }>(this.endpointsService.get('rerbinsetupdocument.searchList'), { searchValue })
            .pipe(tap((data: { rerBinSetupDocumentList: any[] }) => {
                this.rerBinSetupDocumentListSubject.next(data.rerBinSetupDocumentList);
            })
        );
    }

    public getRerPlanningByDate(date: string, currentHour: string = null) {
        let params = currentHour ? new HttpParams().set('time', currentHour) : null;
        return this.httpClient.get<RerPlanning[]>(
            this.endpointsService.get('rer.getPlanningFromDate', [date]), { params: params }
        ).pipe(map((response: any) => {
            return response.map(x => {
                let addresses = [];
                if (x.rer_collection_by_weight_planning_address) {
                    x.rer_collection_by_weight_planning_address.forEach(el => addresses.push(new CollectionByWeightPlanningAddress(el.rer_client_address_id, el.rer_client_id, el.rer_client_address.name, el.rer_client.name, el.minimum_notification_quantity)));
                }
                x.addresses = addresses;
                return x;
            })
        }));
    }

    public copyRerPlanning(copyDate: string, date: string) {
        return this.httpClient.post<RerPlanning[]>(
            this.endpointsService.get('rer.copyPlannings'), { 'date': date, 'copyDate': copyDate });
    }

    public getRfidTagReadingList(rerClientId: number): Observable<RerRfidTagReading[]> {
        return this.httpClient.get<RerRfidTagReading[]>(this.endpointsService.get('rerclient.rfidTagReadings', [rerClientId]));
    }

    public getPlanningRfidTagReadingList(planning_id: number) {
        this.httpClient.get<RerRfidTagReading[]>(this.endpointsService.get('rer.getPlanningReadings', [planning_id])).subscribe(result => {
            let currentPlannings = this.planningsRfidReadingsChangedSubject.getValue();
            currentPlannings.push({ id: planning_id, readings: result });
            this.planningsRfidReadingsChangedSubject.next(currentPlannings);
        });
    }

    public getRfidTagAllocationLog(rfidTagId: number): Observable<RerRfidTagAllocation[]> {
        return this.httpClient.get<RerRfidTagAllocation[]>(this.endpointsService.get('rerclient.rfidtagallocations', [rfidTagId]));
    }

    public getTranslationFor(label: string, value = {}): string {
        let result = '';
        this.translateService.get(label, value).subscribe(
            (response: string) => result = response
        );
        return result;
    }

    public getLocale(): String {
        const lang = localStorage.getItem('currentLang');
        return (lang) ? lang.substring(0, 2) : 'ro';
    }

    public getRerClientById(id: number): RerClient {
        const clients = this.rerClientListSubject.getValue();
        const client = clients.find((cl: RerClient) => cl.id === id);
        if (client !== undefined) {
            return JSON.parse(JSON.stringify(client));
        }
        return null;
    }

    public getRerClientByCode(code: string): RerClient {
        const clients = this.rerClientListSubject.getValue();
        const client = clients.find((cl: RerClient) => cl.code === code);
        if (client !== undefined) {
            return JSON.parse(JSON.stringify(client));
        }
        return null;
    }

    public getRerEmployeeById(id: number): RerEmployee {
        const rerEmployee = this.rerEmployeeListSubject.getValue();
        const employee = rerEmployee.find((emp: RerEmployee) => emp.id === id);
        if (employee !== undefined) {
            return JSON.parse(JSON.stringify(employee));
        }
        return null;
    }

    public getRerBinById(id: number): RerBin {
        const objects = this.rerBinListSubject.getValue();
        const object = JSON.stringify(objects.find((obj: RerBin) => obj.id === id));
        return JSON.parse(object);
    }

    public getUnallocatedRerBinsById(id: number): RerBin {
        const bins = this.unallocatedRerBinsListSubject.getValue();
        const bin = JSON.stringify(bins.find((obj: RerBin) => obj.id === id));
        return JSON.parse(bin);
    }

    private updateRerBinName(rerClient: RerClient): void {
        const bins = this.rerBinListSubject.getValue();
        const newBins = bins.slice(0);

        newBins.forEach(
            (obj: RerBin) => {
                if (obj.rer_client_id === rerClient.id) {
                    obj.rer_client_name = rerClient.name;
                }
            }
        );

        this.rerBinListSubject.next(newBins);
    }

    public addEditClient(client: RerClient): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rerclient.addEdit'), client).pipe(
            map(
                (response: { id: number }) => {
                    client.id = response.id;
                    if (!client.status) {
                        client.status = RerClient.STATUS_ACTIVE;
                    }
                    const newClient = JSON.stringify(client);

                    const clients = this.rerClientListSubject.getValue();
                    const newClients = clients.slice(0);
                    const clientIndex = clients.findIndex((cl: RerClient) => cl.id === client.id);

                    if (clientIndex !== -1) {
                        newClients[clientIndex] = JSON.parse(newClient);
                        this.updateRerBinName(newClients[clientIndex]);
                    } else {
                        client.address = [];
                        newClients.unshift(JSON.parse(newClient));
                    }

                    this.rerClientListSubject.next(newClients);
                    this.rerClientChangedSubject.next(true);

                    return response;
                }
            )
        );
    }

    public addNewEmployee(rerEmployee: RerEmployee): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rer.addEmployee'), rerEmployee).pipe(
            map(
                (response: { id: number }) => {
                    rerEmployee.id = response.id;
                    rerEmployee.type = parseInt(rerEmployee.type);
                    const newEmployee = JSON.stringify(rerEmployee);
                    const employee = this.rerEmployeeListSubject.getValue();
                    const employees = employee.slice(0);
                    employees.unshift(JSON.parse(newEmployee));

                    this.rerEmployeeListSubject.next(employees);
                    return response;
                }
            )
        );
    }

    public updateEmployee(employee: RerEmployee): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rer.updateEmployee'), employee).pipe(
            map(
                (response: { id: number }) => {
                    employee.id = response.id;
                    employee.type = parseInt(employee.type);
                    const newEmployee = JSON.stringify(employee);
                    const employees = this.rerEmployeeListSubject.getValue();
                    const newEmployees = employees.slice(0);
                    const employeeIndex = employees.findIndex((empl: RerEmployee) => empl.id === employee.id);

                    if (employeeIndex !== -1) {
                        newEmployees[employeeIndex] = JSON.parse(newEmployee);
                    }

                    this.rerEmployeeListSubject.next(newEmployees);
                    return response;
                }
            )
        );
    }

    public recoverDeletedClient(code: string): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rerclient.recoverDeleted'), { code: code }).pipe(
            map(
                (response: RerClient) => {
                    const rerClient = response;
                    const clients = this.rerClientListSubject.getValue();
                    const newClients = clients.slice(0);

                    rerClient.address = [];
                    newClients.unshift(rerClient);

                    this.rerClientListSubject.next(newClients);
                    this.rerClientChangedSubject.next(true);
                    return response;
                }
            )
        );
    }

    public deleteClient(clientId: number): Observable<any> {
        return this.httpClient.delete(this.endpointsService.get('rerclient.delete', [clientId])).pipe(
            map(
                (response) => {
                    const clients = this.rerClientListSubject.getValue();
                    const newClients = clients.slice(0);
                    const clientIndex = newClients.findIndex((el: RerClient) => el.id === clientId);
                    if (clientIndex !== -1) {
                        newClients.splice(clientIndex, 1);
                        this.rerClientListSubject.next(newClients);
                        this.rerClientChangedSubject.next(true);
                    }

                    return response;
                }
            )
        );
    }

    public deleteEmployee(employeeId: number): Observable<any> {
        return this.httpClient.delete(this.endpointsService.get('rer.deleteEmployee', [employeeId]))
            .pipe(
                map(
                    (response) => {
                        const employee = this.rerEmployeeListSubject.getValue();
                        const newEmployee = employee.slice(0);
                        const employeeIndex = newEmployee.findIndex((employee: RerEmployee) => employee.id === employeeId);
                        if (employeeIndex !== -1) {
                            newEmployee.splice(employeeIndex, 1);
                            this.rerEmployeeListSubject.next(newEmployee);
                        }

                        return response;
                    }
                )
            );
    }

    public showErrorNotifications(message: string, mWidth = '800px'): void {
        this.actionDialog.open(
            NotificationComponent,
            {
                maxWidth: mWidth, panelClass: 'custom-dialog-container',
                data: { success: 0, headermessage: this.getTranslationFor('error'), message: message }
            }
        );
    }

    public showSaveConfirmation(message: string): void {
        this.actionDialog.open(
            NotificationComponent,
            {
                maxWidth: '800px', panelClass: 'custom-dialog-container',
                data: { success: 1, headermessage: this.getTranslationFor('success'), message: message }
            }
        );
    }

    public getRerClientAddressList(clientId: number): Observable<any> {
        return this.httpClient.get(this.endpointsService.get('rerclientaddress.list', [clientId]));
    }
    public getUserClient() {
        this.httpClient.get(this.endpointsService.get('rer.getUserClient')).subscribe({
            next: (result: RerUserIncident[]) => {
                this.rerUserIncidentsListSubject.next(result);
            }
        });
    }
    public getRerClient() {
        this.httpClient.get(this.endpointsService.get('rer.getRerClient')).subscribe({
            next: (result: RerClientIncident[]) => {
                this.rerClientIncidentsListSubject.next(result);
            }
        });
    }

    public getRerClientAddress(clientId: number) {
        this.httpClient.get(this.endpointsService.get('rer.getRerClientAddress', [clientId])).subscribe({
            next: (result: RerClientAddressIncident[]) => {
                this.rerClientAddressIncidentsListSubject.next(result);
            }
        });
    }

    public getRerIncidentLog(incidentId): Observable<any> {
        const token = this.authService.token;
        if (token === null) {
            this.authService.logout();
            return EMPTY;
        }

        return this.httpClient.get(
            this.endpointsService.get('rer.getIncidentLog', [incidentId])
        );
    }

    public getLogText(log: RerIncidentLog, withUserName = true) {
        let text = '';
        switch (log.type_id) {
            case RerIncidentLog.logTypes.statusChange: {
                text = (withUserName ? '<b>' + log.user.name + '</b> ' : '') + this.translateService.instant('Rer.rerIncident.details.log.statusChange') +
                    '</b> ' + this.getTranslationFor('Rer.rerIncident.details.log.from') + ' <b>' + (log.old_value ? this.getTranslationFor('Rer.rerIncident.details.statuses.' + log.old_value) : '-') + '</b> '
                    + this.getTranslationFor('Rer.rerIncident.details.log.to') + ' <b>' + this.getTranslationFor('Rer.rerIncident.details.statuses.' + log.new_value) + '</b>.';
                break;
            }
            case RerIncidentLog.logTypes.evaluationChange: {
                text = (withUserName ? '<b>' + log.user.name + '</b> ' : '') + this.getTranslationFor('Rer.rerIncident.details.log.evaluationChange') +
                    ' ' + this.getTranslationFor('Rer.rerIncident.details.log.from') + ' <b>' + (log.old_value ? this.getTranslationFor('Rer.rerIncident.details.evaluation.' + log.old_value) : '-') + '</b> '
                    + this.getTranslationFor('Rer.rerIncident.details.log.to') + ' <b>' + (log.new_value ? this.getTranslationFor('Rer.rerIncident.details.evaluation.' + log.new_value) : '-') + '</b>.';
                break;
            }
            case RerIncidentLog.logTypes.rerClientChange: {
                text = (withUserName ? '<b>' + log.user.name + '</b> ' : '') + this.getTranslationFor('Rer.rerIncident.details.log.rerClientChange') +
                    ' ' + this.getTranslationFor('Rer.rerIncident.details.log.from') + ' <b>' + (log.old_value ? log.old_value : '-') + '</b> '
                    + this.getTranslationFor('Rer.rerIncident.details.log.to') + ' <b>' + (log.new_value ? log.new_value : '-') + '</b>.';
                break;
            }
            case RerIncidentLog.logTypes.addressChange: {
                text = (withUserName ? '<b>' + log.user.name + '</b> ' : '') + this.getTranslationFor('Rer.rerIncident.details.log.addressChange') +
                    ' ' + this.getTranslationFor('Rer.rerIncident.details.log.from') + ' <b>' + (log.old_value ? log.old_value : '-') + '</b> '
                    + this.getTranslationFor('Rer.rerIncident.details.log.to') + ' <b>' + (log.new_value ? log.new_value : '-') + '</b>.';
                break;
            }
            case RerIncidentLog.logTypes.responsibleChange: {
                text = (withUserName ? '<b>' + log.user.name + '</b> ' : '') + this.getTranslationFor('Rer.rerIncident.details.log.responsibleChange') +
                    ' ' + this.getTranslationFor('Rer.rerIncident.details.log.from') + ' <b>' + (log.old_value ? log.old_value : '-') + '</b> '
                    + this.getTranslationFor('Rer.rerIncident.details.log.to') + ' <b>' + (log.new_value ? log.new_value : '-') + '</b>.';
                break;
            }
            case RerIncidentLog.logTypes.notesChange: {
                text = (withUserName ? '<b>' + log.user.name + '</b> ' : '') + this.getTranslationFor('Rer.rerIncident.details.log.notesChange') +
                    ' ' + this.getTranslationFor('Rer.rerIncident.details.log.from') + ' <b>' + (log.old_value ? log.old_value : '-') + '</b> '
                    + this.getTranslationFor('Rer.rerIncident.details.log.to') + ' <b>' + (log.new_value ? log.new_value : '-') + '</b>.';
                break;
            }
            default: {
                break;
            }
        }
        return text;
    }

    public getFirstWords(str: string, number: number) {
        let arr = str.split(" ");
        let strToReturn = '';
        let i = 0;
        while (i < number) {
            if (arr[i]) {
                strToReturn += arr[i] + ' ';
            }
            i++;
        }
        if (arr.length > i) {
            strToReturn += '[...]';
        }
        return strToReturn;
    }

    public addEditRerClientAddress(address: RerClientAddress): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rerclientaddress.addEdit'), address).pipe(map(
            (res: { id: number }) => {
                address.id = res.id;

                const rerClientAddresses = this.rerClientAddressListSubject.getValue();
                const addIndex = rerClientAddresses.findIndex((el: RerClientAddress) => el.id === address.id);
                if (addIndex !== -1) {
                    rerClientAddresses[addIndex] = address;
                } else {
                    rerClientAddresses.push(address);
                }

                if (address.rer_area_id) {
                    const area = this.getAreaById(address.rer_area_id);
                }
                if (address.is_default) {
                    rerClientAddresses.forEach(
                        (el: RerClientAddress) => {
                            if (el.id !== address.id && el.is_default === 1) {
                                el.is_default = 0;
                            }
                        }
                    );
                    this.updateDefaultClientAddress(address.rer_client_id, address);
                }

                this.rerClientAddressListSubject.next(rerClientAddresses);

                return true;
            }
        ));
    }

    public updateDefaultClientAddress(clientId: number, clientAddress: RerClientAddress): void {
        const clients = this.rerClientListSubject.getValue();
        const newClients = clients.slice(0);
        const clientIndex = clients.findIndex((cl: RerClient) => cl.id === clientId);

        if (clientIndex !== -1) {
            newClients[clientIndex].address = clientAddress.address;
            newClients[clientIndex].city = clientAddress.city;
            newClients[clientIndex].division = clientAddress.division;
            newClients[clientIndex].street = clientAddress.street;
            newClients[clientIndex].street_number = clientAddress.street_number;
            this.rerClientListSubject.next(newClients);
            this.rerClientChangedSubject.next(true);
        }
    }

    public deleteClientAddress(id: number): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rerclientaddress.delete'), { id: id });
    }

    public getRerBinsList(clientId: number): Observable<any> {
        return this.httpClient.get(this.endpointsService.get('rerclient.listOfBins', [clientId]));
    }

    private getRerClientWithRerBinsList() {
        return this.httpClient.get<any[]>(this.endpointsService.get('user.getUserListWithObjects')).pipe(map(
            data => this.prepareRerClientAssociations(data)
        )).subscribe((response) => {
            this.rerClientAssociationsListSubject.next(response);
        });
    }

    private getRerClientSetupWithRerBinsList() {
        return this.httpClient.get<any[]>(this.endpointsService.get('user.getUserListWithSetupDocument')).pipe(map(
            data => this.prepareSetupDocumentAssociations(data)
        )).subscribe((response) => {
            this.rerBinSetupDocumentAssociationsListSubject.next(response);
        });
    }

    private prepareSetupDocumentAssociations(data: any): RerBinSetupDocumentAssociation[] {
        const users = [];
        Object.keys(data).forEach(key =>
            users.push(
                new RerBinSetupDocumentAssociation(
                    data[key].id,
                    data[key].name,
                    data[key].rer_bin_setup_documents.map(
                        (obj: any) => new RerBinSetupDocument(
                            obj.id,
                            obj.number,
                            obj.rer_client_name,
                            obj.rer_client_id,
                            obj.signed_at,
                            null,
                            null,
                            null,
                            obj.count_bins,
                            obj.address,
                            obj.city,
                            obj.division,
                            obj.street,
                            obj.street_number,
                            obj.bin_type
                        )
                    )
                )
            ));
        return users;
    }

    public getUnallocatedBinsList(): void {
        this.httpClient.get(this.endpointsService.get('rerbin.unallocatedList')).subscribe(
            (data: RerBin[]) => {
                this.unallocatedRerBinsListSubject.next(data);
            });
    }

    public addEditRerBin(rerBin: RerBin, addClientName = false): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rerbin.addEdit'), rerBin).pipe(map(
            (res: { id: number }) => {
                rerBin.id = res.id;
                const bins = this.rerBinListSubject.getValue();
                const newBins = bins.slice(0);
                if (addClientName) {
                    const client = this.getRerClientById(rerBin.rer_client_id);
                    if (client) {
                        rerBin.rer_client_name = client.name;
                    }
                }

                if (rerBin.rer_waste_type_id) {
                    const wasteType = this.getWasteTypeById(rerBin.rer_waste_type_id);
                    rerBin.waste_type = wasteType.name;
                }

                if (rerBin.rer_bin_type_id) {
                    const binType = this.getBinTypeById(rerBin.rer_bin_type_id);
                    rerBin.bin_type = binType.name;
                }

                if (rerBin.rer_bin_ownership_id) {
                    const binOwnership = this.getRerBinOwnershipById(rerBin.rer_bin_ownership_id);
                    rerBin.bin_ownership = binOwnership.name;
                }

                const binIndex = newBins.findIndex((el: RerBin) => el.id === rerBin.id);
                if (binIndex !== -1) {
                    const binJson = JSON.stringify(rerBin);
                    const newBin = JSON.parse(binJson);
                    newBins[binIndex] = newBin;
                } else {
                    if (rerBin.status === null) {
                        rerBin.status = RerBin.STATUS_ACTIVE;
                    }
                    newBins.push(rerBin);
                }

                this.rerBinListSubject.next(newBins);

                const hasRfidTagsManagement = this.hasRight(AuthService.USER_RIGHT_RER_RFID_MANAGEMENT);
                if (hasRfidTagsManagement) {
                    this.httpClient.get<any[]>(this.endpointsService.get('rfidtag.listAvailable')).subscribe(
                        (response) => {
                            this.rerRfidTagListSubject.next(response);
                        }
                    );
                }

                this.getRerClientChanges(rerBin.rer_client_id);
                return true;
            }
        ));
    }

    public addEditUnallocatedBin(rerBin: RerBin): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rerbin.addEditUnallocated'), rerBin).pipe(map(
            (res: { id: number }) => {
                rerBin.id = res.id;
                const bins = this.unallocatedRerBinsListSubject.getValue();
                const newBins = bins.slice(0);

                if (rerBin.rer_rfid_tag_id) {
                    const rfidTag = this.getRfidTagById(rerBin.rer_rfid_tag_id);
                    if (rfidTag) {
                        rerBin.rer_rfid = rfidTag.rfid;
                    }
                } else {
                    rerBin.rer_rfid = null;
                }

                const binIndex = newBins.findIndex((el: RerBin) => el.id === rerBin.id);
                if (binIndex !== -1) {
                    const binJson = JSON.stringify(rerBin);
                    const newBin = JSON.parse(binJson);
                    newBin.description = rerBin.description;


                    newBin.rer_rfid_tag_id = rerBin.rer_rfid_tag_id;
                    newBins[binIndex] = newBin;
                } else {
                    newBins.push(rerBin);
                }

                this.unallocatedRerBinsListSubject.next(newBins);

                const hasRfidTagsManagement = this.hasRight(AuthService.USER_RIGHT_RER_RFID_MANAGEMENT);
                if (hasRfidTagsManagement) {
                    this.httpClient.get<any[]>(this.endpointsService.get('rfidtag.listAvailable')).subscribe(
                        (response) => {
                            this.rerRfidTagListSubject.next(response);
                        }
                    );
                }

                const hasUnallocatedRerBin = this.hasRight(AuthService.USER_RIGHT_RER_UNALLOCATED_RER_BINS);
                if (hasUnallocatedRerBin) {
                    this.getUnallocatedBinsList();
                }

                return true;
            }
        ));
    }

    public editUnalocatedRerBinChangeStatus(rerBin: RerBin): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rerbin.changeobjectstatus'), rerBin).pipe(map(
            (res: { id: number }) => {
                let allocateStatus = false;
                let deallocateStatus = false;
                if (rerBin.status === RerBin.STATUS_ACTIVE) {
                    allocateStatus = true;
                } else if (rerBin.status === RerBin.STATUS_AVAILABLE) {
                    deallocateStatus = true;
                }
                rerBin.id = res.id;
                let bins = this.unallocatedRerBinsListSubject.getValue();
                if (allocateStatus) {
                    bins = this.rerBinListSubject.getValue();
                }

                const newBins = bins.slice(0);
                if (rerBin.rer_rfid_tag_id) {
                    const rfidTag = this.getRfidTagById(rerBin.rer_rfid_tag_id);
                    if (rfidTag) {
                        rerBin.rer_rfid = rfidTag.rfid;
                    }
                } else {
                    rerBin.rer_rfid = null;
                }

                const binIndex = newBins.findIndex((el: RerBin) => el.id === rerBin.id);
                if (binIndex !== -1) {
                    const binJson = JSON.stringify(rerBin);
                    const newBin = JSON.parse(binJson);
                    newBin.description = rerBin.description;
                    newBin.status = rerBin.status;
                    if (deallocateStatus) {
                        rerBin.rer_client_id = null;
                    }
                    newBin.rer_rfid_tag_id = rerBin.rer_rfid_tag_id;
                    newBins[binIndex] = newBin;
                } else {
                    newBins.push(rerBin);
                }

                if (allocateStatus) {
                    const unallocatedBins = this.unallocatedRerBinsListSubject.getValue();
                    const newUnallocatedBins = unallocatedBins.slice(0);
                    const unnallocatedBinIndex = newUnallocatedBins.findIndex((el: RerBin) => el.id === rerBin.id);
                    if (unnallocatedBinIndex !== -1) {
                        newUnallocatedBins.splice(unnallocatedBinIndex, 1);
                        this.unallocatedRerBinsListSubject.next(newUnallocatedBins);
                    }
                    this.rerBinListSubject.next(newBins);
                } else {
                    if (deallocateStatus) {
                        const unallocatedBin = this.rerBinListSubject.getValue();
                        const newUnallocatedBins = unallocatedBin.slice(0);
                        const unallocatedBinsIndex = newUnallocatedBins.findIndex((el: RerBin) => el.id === rerBin.id);
                        if (unallocatedBinsIndex !== -1) {
                            newUnallocatedBins.splice(unallocatedBinsIndex, 1);
                            this.unallocatedRerBinsListSubject.next(newUnallocatedBins);
                        }
                    }

                    this.rerBinListSubject.next(newBins);
                }

                const hasRfidTagsManagement = this.hasRight(AuthService.USER_RIGHT_RER_RFID_MANAGEMENT);
                if (hasRfidTagsManagement) {
                    this.httpClient.get<any[]>(this.endpointsService.get('rfidtag.listAvailable')).subscribe(
                        (response) => {
                            this.rerRfidTagListSubject.next(response);
                            return true;
                        }
                    );
                }

                const hasUnallocatedObjList = this.hasRight(AuthService.USER_RIGHT_RER_UNALLOCATED_RER_BINS);
                if (hasUnallocatedObjList) {
                    this.getUnallocatedBinsList();
                }
                if (rerBin.status === RerBin.STATUS_SUSPENDED) {
                    this.getRerClients(
                        {
                            cityList: rerBin.city,
                            divisionList: null,
                            typeList: null,
                            streetList: null,
                            activeOnly: false
                        }
                    ).subscribe(
                        () => {
                            this.rerClientChangedSubject.next(true);
                        }
                    );
                }

                const hasUserObjectAllocation = this.hasRight(AuthService.USER_RIGHT_RER_CLIENT_ASSOCIATION);
                if (hasUserObjectAllocation) {
                    this.getRerClientWithRerBinsList();
                }
                const hasRerSetupDocumentAllocation = this.hasRight(AuthService.USER_RIGHT_RER_CLIENT_BIN_SETUP_DOC_ASSOCIATION);
                if (hasRerSetupDocumentAllocation) {
                    this.getRerClientSetupWithRerBinsList();
                }

                if (rerBin.rer_client_id) {
                    this.getRerClientChanges(rerBin.rer_client_id);
                }

                return true;
            }
        ));
    }

    public editRerClientChangeStatus(client: RerClient, status: number): Observable<any> {
        client.status = status;
        return this.httpClient.post(this.endpointsService.get('rerclient.changeStatus'), client).pipe(map(
            (res: { id: number }) => {
                client.id = res.id;
                const clients = this.rerClientListSubject.getValue();
                const newClients = clients.slice(0);
                const clientIndex = newClients.findIndex((el: RerClient) => el.id === client.id);

                if (clientIndex !== -1) {
                    const clientJson = JSON.stringify(client);
                    const newClient = JSON.parse(clientJson);
                    newClient.description = client.description;
                    newClient.status = client.status;
                    newClients[clientIndex] = newClient;
                }

                this.rerClientListSubject.next(newClients);

                const hasUserObjectAllocation = this.hasRight(AuthService.USER_RIGHT_RER_CLIENT_ASSOCIATION);
                if (hasUserObjectAllocation) {
                    this.getRerClientWithRerBinsList();
                }
                const hasRerSetupDocumentAllocation = this.hasRight(AuthService.USER_RIGHT_RER_CLIENT_BIN_SETUP_DOC_ASSOCIATION);
                if (hasRerSetupDocumentAllocation) {
                    this.getRerClientSetupWithRerBinsList();
                }

                this.getRerClientChanges(client.id);
                return true;
            }
        ));
    }

    public deleteRerBin(id: number): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rerbin.delete'), { id: id }).pipe(map(
            () => {
                const bins = this.rerBinListSubject.getValue();
                const newBins = bins.slice(0);
                const binIndex = newBins.findIndex((el: RerBin) => el.id === id);
                if (binIndex !== -1) {
                    newBins.splice(binIndex, 1);
                    this.rerBinListSubject.next(newBins);
                }
                return true;
            }
        ));
    }

    public deleteUnallocatedRerBin(id: number): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rerbin.delete'), { id: id }).pipe(map(
            () => {
                const bins = this.unallocatedRerBinsListSubject.getValue();
                const newBins = bins.slice(0);
                const binIndex = newBins.findIndex((el: RerBin) => el.id === id);
                if (binIndex !== -1) {
                    newBins.splice(binIndex, 1);
                    this.unallocatedRerBinsListSubject.next(newBins);
                }

                return true;
            }
        ));
    }

    private evaluateRFIDFilter(rfid: string, rfidFilter: number): boolean {
        switch (rfidFilter) {
            case 0:
                return true;
            case 1:
                return rfid ? true : false;
            case 2:
                return !rfid ? true : false;
            default:
                return false;
        }
    }

    public evaluateFilter(bin: RerBin, filter: string, rfidFilter: number): boolean {
        return ((
            (bin.waste_type && bin.waste_type.toLowerCase().includes(filter.toLowerCase())) ||
            (bin.address && bin.address.toLowerCase().includes(filter.toLowerCase())) ||
            (bin.rer_client_name && bin.rer_client_name.toLowerCase().includes(filter.toLowerCase())) ||
            (bin.division && bin.division.toLowerCase().includes(filter.toLowerCase())) ||
            (bin.street && bin.street.toLowerCase().includes(filter.toLowerCase())) ||
            (bin.street_number && bin.street_number.toLowerCase().includes(filter.toLowerCase())) ||
            // (bin.name && bin.name.toLowerCase().includes(filter.toLowerCase())) ||
            (bin.bin_type && bin.bin_type.toLowerCase().includes(filter.toLowerCase())) ||
            (bin.city && bin.city.toLowerCase().includes(filter.toLowerCase()))
        ) && this.evaluateRFIDFilter(bin.rer_rfid, rfidFilter));
    }

    public evaluateFilterSetupDocument(document: RerBinSetupDocument, filter: string, status: number): boolean {
        return ((
            (document.address && document.address.toLowerCase().includes(filter.toLowerCase())) ||
            (document.rer_client_name && document.rer_client_name.toLowerCase().includes(filter.toLowerCase())) ||
            (document.division && document.division.toLowerCase().includes(filter.toLowerCase())) ||
            (document.street && document.street.toLowerCase().includes(filter.toLowerCase())) ||
            (document.street_number && document.street_number.toLowerCase().includes(filter.toLowerCase())) ||
            (document.number && document.number.toLowerCase().includes(filter.toLowerCase())) ||
            (document.city && document.city.toLowerCase().includes(filter.toLowerCase())) ||
            (document.bin_type && document.bin_type.toLowerCase().includes(filter.toLowerCase()))
        ) && this.evaluateStatusFilter(document, status));
    }

    public rerBinsListFilter(filter = '', rfidFilter: number): Observable<RerBin[]> {
        const rerClientAssociationsList = this.rerClientAssociationsListSubject.getValue();
        let binIds = [];
        rerClientAssociationsList.forEach((el: RerClientAssociation) => binIds = binIds.concat(el.rer_bins.map(obj => obj.id)));
        
        if (binIds.length === 0) {
            return this.rerBinList$.pipe(
                map((bins: RerBin[]) => bins.filter((bin: RerBin) => this.evaluateFilter(bin, filter, rfidFilter)))
            );
        }
        return this.rerBinList$.pipe(
            map(
                (bins: RerBin[]) => bins.filter(
                    (bin: RerBin) => binIds.findIndex(
                        el => el === bin.id) === -1 && this.evaluateFilter(bin, filter, rfidFilter) ? true : false
                )
            )
        );
    }

    public evaluateStatusFilter(document, status){
        switch (status) {
            case 0:
                return true;
            case 1:
                return document.signed_at != null;
            case 2:
                return document.signed_at == null;
            default:
                return false;
        }
    }

    public rerBinsSetupDocumentListFilter(filter = '', status: number): Observable<RerBinSetupDocument[]> {
        const rerClientAssociationsList = this.rerBinSetupDocumentAssociationsListSubject.getValue();
        let binIds = [];
        rerClientAssociationsList.forEach((el: RerBinSetupDocumentAssociation) => binIds = binIds.concat(el.rer_bin_setup_documents.map(obj => obj.id)));
        
        if (binIds.length === 0) {
            return this.rerBinSetupDocumentList$.pipe(
                map((binSetupDocs: RerBinSetupDocument[]) => binSetupDocs.filter((binSetupDoc: RerBinSetupDocument) => this.evaluateFilterSetupDocument(binSetupDoc, filter, status)))
            );
        }
        return this.rerBinSetupDocumentList$.pipe(
            map(
                (binSetupDocuments: RerBinSetupDocument[]) => binSetupDocuments.filter(
                    (binSetupDocument: RerBinSetupDocument) => binIds.findIndex(
                        el => el === binSetupDocument.id) === -1 &&  this.evaluateFilterSetupDocument(binSetupDocument, filter, status) ? true : false
                )
            )
        );
    }

    public updateRerClientAssociations(isRemoval: boolean, userId: number, binsIds: number[]): Observable<RerBin[]> {
        const rerClientAssociationsList = JSON.stringify(this.rerClientAssociationsListSubject.getValue());
        const newRerClientAssociations = JSON.parse(rerClientAssociationsList);
        const clientAssociation: RerClientAssociation = newRerClientAssociations.find((rca: RerClientAssociation) => rca.id === userId);
        let binIds = [];
        if (isRemoval) {
            binIds = clientAssociation.rer_bins.filter((bin: RerBin) => binsIds.findIndex(el => el === bin.id) === -1)
                .map((bin: RerBin) => bin.id);
        } else {
            binIds = binsIds.concat(clientAssociation.rer_bins.map((bin: RerBin) => bin.id));
        }

        const requestBin = { userId: clientAssociation.id, binIds: binIds };
        return this.httpClient.post(this.endpointsService.get('rer.updateuserobjects'), requestBin)
            .pipe(map(() => {
                if (isRemoval) {
                    clientAssociation.rer_bins = clientAssociation.rer_bins.filter(
                        (obj: RerBin) => binIds.findIndex(id => id === obj.id) !== -1);
                } else {
                    const newBins = this.rerBinListSubject.getValue().filter(
                        (bin: RerBin) => binsIds.findIndex(id => id === bin.id) !== -1);
                    clientAssociation.rer_bins = clientAssociation.rer_bins.concat(newBins);
                }
                this.rerClientAssociationsListSubject.next(newRerClientAssociations);

                return clientAssociation.rer_bins;
            }));
    }

    public updateRerBinSetupDocumentAssociations(isRemoval: boolean, userId: number, binsIds: number[]): Observable<RerBinSetupDocument[]> {
        const rerClientAssociationsList = JSON.stringify(this.rerBinSetupDocumentAssociationsListSubject.getValue());
        const newRerClientAssociations = JSON.parse(rerClientAssociationsList);
        const clientAssociation: RerBinSetupDocumentAssociation = newRerClientAssociations.find((rca: RerBinSetupDocumentAssociation) => rca.id === userId);
        let binSetupDocumentIds = [];
        if (isRemoval) {
            binSetupDocumentIds = clientAssociation.rer_bin_setup_documents.filter((binDoc: RerBinSetupDocument) => binsIds.findIndex(el => el === binDoc.id) === -1)
                .map((binDoc: RerBinSetupDocument) => binDoc.id);
        } else {
            binSetupDocumentIds = binsIds.concat(clientAssociation.rer_bin_setup_documents.map((binDoc: RerBinSetupDocument) => binDoc.id));
        }
        const requestBinDoc = { userId: clientAssociation.id, binSetupDocumentsIds: binSetupDocumentIds };
        return this.httpClient.post(this.endpointsService.get('rer.updateuserbinsetupdocuments'), requestBinDoc)
            .pipe(map(() => {
                if (isRemoval) {
                    clientAssociation.rer_bin_setup_documents = clientAssociation.rer_bin_setup_documents.filter(
                        (obj: RerBinSetupDocument) => binSetupDocumentIds.findIndex(id => id === obj.id) !== -1);
                } else {
                    const newBinDocss = this.rerBinSetupDocumentListSubject.getValue().filter(
                        (obj: RerBinSetupDocument) => binsIds.findIndex(id => id === obj.id) !== -1);
                    clientAssociation.rer_bin_setup_documents = clientAssociation.rer_bin_setup_documents.concat(newBinDocss);
                }

                this.rerBinSetupDocumentAssociationsListSubject.next(newRerClientAssociations);
                return clientAssociation.rer_bin_setup_documents;
            }));
    }

    public processChangedRerBins(currentBins: RerBin[], receivedBins: RerBin[]): void {
        receivedBins.forEach(
            (rbin: RerBin) => {
                const rerBin = currentBins.find((bin: RerBin) => bin.id === rbin.id);
                if (rerBin) {
                    rerBin.rer_rfid = rbin.rer_rfid;
                }
            }
        );
    }

    public processChangedRerBinSetupDocs(currentBinSetupDocs: RerBinSetupDocument[], receivedBinDocs: RerBinSetupDocument[]): void {
        receivedBinDocs.forEach(
            (binRerDoc: RerBinSetupDocument) => {
                const rerBinSetupDocument = currentBinSetupDocs.find((binDoc: RerBinSetupDocument) => binRerDoc.id === binDoc.id);
                if (rerBinSetupDocument) {
                    rerBinSetupDocument.signed_at = binRerDoc.signed_at;
                }
            }
        );
    }

    public onReceiveWssNotifications(data: { user_id: number, objects: RerBin[] }): void {
        const clientAssociationsList = JSON.stringify(this.rerClientAssociationsListSubject.getValue());
        const newClientAssociationsList = JSON.parse(clientAssociationsList);
        const clientAssociation = newClientAssociationsList.find((clientAssociation: RerClientAssociation) => clientAssociation.id === data.user_id);
        if (clientAssociation) {
            this.processChangedRerBins(clientAssociation.objects, data.objects);
            this.rerClientAssociationsListSubject.next(newClientAssociationsList);
        }
    }
    public onReceiveWssSetupDocsNotifications(data: { user_id: number, objects: RerBinSetupDocument[] }): void {
        const clientAssociationsList = JSON.stringify(this.rerBinSetupDocumentAssociationsListSubject.getValue());
        const newClientAssociationsList = JSON.parse(clientAssociationsList);
        const clientAssociation = newClientAssociationsList.find((clientAssociation: RerBinSetupDocumentAssociation) => clientAssociation.id === data.user_id);
        if (clientAssociation) {
            // this.processChangedRerBins(clientAssociation.objects, data.objects);
            this.rerBinSetupDocumentAssociationsListSubject.next(newClientAssociationsList);
        }
    }

    public importRerClientData(requestData: { importData: any[] }): Observable<any[]> {
        return this.httpClient.post<any[]>(this.endpointsService.get('rer.importRerClientData'), requestData).pipe(
            map((response: any) => {
                this.getAllDatas();
                return response;
            })
        );
    }

    public importRerBinSetupData(requestData: { importData: any[] }): Observable<any[]> {
        return this.httpClient.post<any[]>(this.endpointsService.get('rer.importRerBinSetupData'), requestData).pipe(
            map((response: any) => {
                this.getAllDatas();
                return response;
            })
        );
    }

    public sendBinSetupByEmail(rerBinSetupDocumentId: number): Observable<any> {
        return this.httpClient.post<any[]>(this.endpointsService.get('rerbin.sendBinSetupByEmail'), {rerBinSetupDocumentId : rerBinSetupDocumentId}).pipe(
            map((response: any) => {
                this.getAllDatas();
                return response;
            })
        );
    }

    public emptyRerBinsList(): void {
        this.rerBinListSubject.next([]);
    }

    public emptyRerBinSetupDocumentsList(): void {
        this.rerBinSetupDocumentListSubject.next([]);
    }

    public importRerTagData(requestData: { importData: any[] }): Observable<any[]> {
        return this.httpClient.post<any[]>(this.endpointsService.get('rer.importRerTagData'), requestData).pipe(
            map((response: any) => {
                this.getAllDatas();
                return response;
            })
        );
    }

    public addEditRfidTag(rfidTag: RerRfidTag): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rfidtag.addEdit'), rfidTag).pipe(map(
            (res: any) => {
                rfidTag = res
                const rfidTags = this.rerRfidTagListSubject.getValue();
                const newRfidTags = rfidTags.slice(0);

                const rfidTagsIndex = newRfidTags.findIndex((el: RerRfidTag) => el.id === rfidTag.id);
                if (rfidTagsIndex !== -1) {
                    const rfidTagJson = JSON.stringify(rfidTag);
                    const newrfidTag = JSON.parse(rfidTagJson);
                    newRfidTags[rfidTagsIndex] = newrfidTag;
                } else {
                    newRfidTags.push(rfidTag);
                }

                this.rerRfidTagListSubject.next(newRfidTags);

                return true;
            }
        ));
    }

    public deleteRfidTag(id: number): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rfidtag.delete'), { id: id }).pipe(map(
            () => {
                const rfidTags = this.rerRfidTagListSubject.getValue();
                const newTags = rfidTags.slice(0);
                const rfidTagIndex = newTags.findIndex((el: RerRfidTag) => el.id === id);
                if (rfidTagIndex !== -1) {
                    newTags.splice(rfidTagIndex, 1);
                    this.rerRfidTagListSubject.next(newTags);
                }

                return true;
            }
        ));
    }

    public getRfidTagById(id: number): RerRfidTag {
        const tags = this.rerRfidTagListSubject.getValue();
        const tag = tags.find((obj: RerRfidTag) => obj.id === id);
        if (tag !== undefined) {
            const rfidTag = JSON.stringify(tags.find((obj: RerRfidTag) => obj.id === id));
            return JSON.parse(rfidTag);
        }

        return null;
    }

    public getPlannings(startDate: any, endDate: any): Observable<RerPlanning[]> {
        return this.httpClient.get<any[]>(this.endpointsService.get('rer.getPlannings', [startDate, endDate])).pipe(map((response: any) => {
            return response.map(x => {
                let addresses = [];
                if (x.rer_collection_by_weight_planning_address) {
                    x.rer_collection_by_weight_planning_address.forEach(el => addresses.push(new CollectionByWeightPlanningAddress(el.rer_client_address_id, el.rer_client_id, el.rer_client_address.name, el.rer_client.name, el.minimum_notification_quantity)));
                }
                x.addresses = addresses;
                return x;
            })
        }));
    }
    public getIncidentList(startDate: String, endDate: String): Observable<RerIncident[]> {
        let params = new HttpParams().set("period_begin", String(startDate))
            .set("period_end", String(endDate));
        const httpOptions = {
            params: params
        };
        return this.httpClient.get<any[]>(this.endpointsService.get('rer.getIncidents'), httpOptions);
    }

    public getPlanningById(id: number): RerPlanning {
        const plannings = this.rerPlanningListSubject.getValue();
        const planning = plannings.find((obj: RerPlanning) => obj.id === id);
        if (planning !== undefined) {
            const rerPlanning = JSON.stringify(plannings.find((obj: RerPlanning) => obj.id === id));
            return JSON.parse(rerPlanning);
        }

        return null;
    }

    public editPlanning(object: RerPlanning): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rer.updatePlanning', [object.id]), object).pipe(map(
            (res: number) => {
                object.id = res;
                const objects = this.rerPlanningListSubject.getValue();
                const newObjects = objects.slice(0);

                const driver = this.getRerEmployeeById(object.main_employee_id);
                object.main_employee_name = driver.name;
                const op1 = this.getRerEmployeeById(object.secundary_employee_id);
                object.secundary_employee_name = op1.name;
                if (object.tertiary_employee_id) {
                    const op2 = this.getRerEmployeeById(object.tertiary_employee_id);
                    object.tertiary_employee_name = op2.name;
                } else {
                    object.tertiary_employee_name = '';
                }
                if (object.rer_waste_type_id) {
                    const wasteType = this.getWasteTypeById(object.rer_waste_type_id);
                    object.waste_type = wasteType.name;
                }
                if (object.device_id) {
                    const device = this.getDeviceById(object.device_id);
                    object.device_name = device.name;
                }

                const objectIndex = newObjects.findIndex((el: RerPlanning) => el.id === object.id);
                if (objectIndex !== -1) {
                    const objJson = JSON.stringify(object);
                    const newObj = JSON.parse(objJson);
                    newObjects[objectIndex] = newObj;
                } else {
                    newObjects.push(object);
                }

                this.rerPlanningListSubject.next(newObjects);

                return true;
            }
        ));
    }

    public deletePlanning(planningId: number): Observable<any> {
        return this.httpClient.delete(this.endpointsService.get('rer.deletePlanning', [planningId]))
            .pipe(
                map(
                    (response) => {
                        const plannings = this.rerPlanningListSubject.getValue();
                        const newPlanning = plannings.slice(0);
                        const planningTBDIndex = newPlanning.findIndex((planning: RerPlanning) => planning.id === planningId);
                        if (planningTBDIndex !== -1) {
                            newPlanning.splice(planningTBDIndex, 1);
                            this.rerPlanningListSubject.next(newPlanning);
                            RerService.rerDashboardListSubject.next(newPlanning);
                            this.spinnerSubject.next(false);
                        }

                        return response;
                    }
                )
            );
    }

    public updateDashboarList() {
        this.getRerPlanningByDate(
            moment().format('YYYY-MM-DD'),
            moment().utc().format('HH:mm')
        ).subscribe((response: RerPlanning[]) => {
            RerService.rerDashboardListSubject.next(response);
            this.spinnerSubject.next(false)
        });
        this.getWasteTypeList().subscribe(
            (wasteTypes: RerWasteType[]) => {
                this.rerWasteTypeListSubject.next(wasteTypes);
            }
        );
        this.getRerBinOwnershipList().subscribe(
            (rerBinOwnerships: RerBinOwnership[]) => {
                this.rerBinOwnershipListSubject.next(rerBinOwnerships);
            }
        );
        this.getBinTypeList().subscribe(
            (binTypes: RerBinType[]) => {
                this.rerBinTypeListSubject.next(binTypes);
            }
        );
        this.getAreaList().subscribe({
            next: (areas: RerArea[]) => {
                this.rerAreaListSubject.next(areas);
            }
        }
        );
        const dateTo = moment().format('YYYY-MM-DD');
        const dateFrom = moment().subtract(7, 'd').format('YYYY-MM-DD');
        this.getIncidentList(dateFrom, dateTo).subscribe({
            next: (incidents: RerIncident[]) => {
                this.rerIncidentListSubject.next(incidents);
            }
        }
        );
    }

    private updateAntennas(id: number, antenna1: string, antenna2: string): void {
        const objects = RerService.rerDashboardListSubject.getValue();
        const objectIndex = objects.findIndex((el: RerPlanning) => el.id === id);

        if (objectIndex !== -1) {
            objects[objectIndex].antenna1_last_reading = antenna1;
            objects[objectIndex].antenna2_last_reading = antenna2;
        }

        RerService.rerDashboardListSubject.next(objects);
    }

    private updatePlannings(planningsReadings: any) {
        Object.keys(planningsReadings).forEach(element => {
            let currentPlannings = this.planningsRfidReadingsChangedSubject.getValue();
            let planningToUpdate = currentPlannings.find(x => x.id == parseInt(element));
            if (planningToUpdate) {
                planningToUpdate.readings = planningsReadings[element].concat(planningToUpdate.readings);
            }
            this.planningsRfidReadingsChangedSubject.next(currentPlannings);
        });
    }

    public handleWssMessage(payload: any, entity: any) {
        if (entity) {
            switch (entity) {
                case this.UPDATE_PLANNNINGS_READINGS_ENTITY:
                    this.updatePlannings(payload);
                    return;
            }

        } else {
            switch (payload.action) {
                case this.ACTION_REALOAD_DASHBOARD:
                    this.updateDashboarList();
                    return;
                case this.ACTION_UPDATE_PLANNNING_ANTENNA:
                    this.updateAntennas(payload.planning_id, payload.antenna1_last_reading, payload.antenna2_last_reading);
                    return;
            }
        }
    }

    public getRerSettings(): Observable<any> {
        return this.httpClient.get<any[]>(this.endpointsService.get('rer.getSettings'));
    }

    public updateRerSettings(settings: RerSettings[]): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rer.updateSettings'), { settings });
    }

    public getSettingsLogs(): Observable<any> {
        return this.httpClient.get<any[]>(this.endpointsService.get('rer.getSettingsLogs'));
    }

    public getPlanningsLogs(): Observable<any> {
        return this.httpClient.get<any[]>(this.endpointsService.get('rer.getPlanningLogs'));
    }

    public getWasteTypeList(): Observable<any> {
        return this.httpClient.get<any[]>(this.endpointsService.get('rer.getWasteTypeList'));
    }

    public getRerBinOwnershipList(): Observable<any> {
        return this.httpClient.get<any[]>(this.endpointsService.get('rer.getRerBinOwnershipList'));
    }

    public getAreaList(): Observable<any> {
        return this.httpClient.get<any[]>(this.endpointsService.get('rer.getAreaList'));
    }

    public getEmailTemplate() {
        return this.httpClient.get<any[]>(this.endpointsService.get('rer.getEmailTemplate')).subscribe({
            next: (response) => this.rerEmailTemplateListSubject.next(response)
        });
    }

    public saveWasteType(wasteType: RerWasteType): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rer.addEditWasteType'), wasteType).pipe(
            map(
                (response: number) => {
                    wasteType.id = response;
                    const newWtyp = JSON.stringify(wasteType);

                    const wtyps = this.rerWasteTypeListSubject.getValue();
                    const newWtyps = wtyps.slice(0);
                    const wTypIndex = wtyps.findIndex((cl: RerWasteType) => cl.id === wasteType.id);

                    if (wTypIndex !== -1) {
                        newWtyps[wTypIndex] = JSON.parse(newWtyp);
                    } else {
                        newWtyps.unshift(JSON.parse(newWtyp));
                    }

                    this.rerWasteTypeListSubject.next(newWtyps);
                    return response;
                }
            )
        );
    }

    public deleteWasteType(wasteTypeId: number): Observable<any> {
        return this.httpClient.delete(this.endpointsService.get('rer.deleteWasteType', [wasteTypeId])).pipe(
            map(
                (response) => {
                    const wtyps = this.rerWasteTypeListSubject.getValue();
                    const newWtyps = wtyps.slice(0);
                    const wtypIndex = newWtyps.findIndex((ca: RerWasteType) => ca.id === wasteTypeId);
                    if (wtypIndex !== -1) {
                        newWtyps.splice(wtypIndex, 1);
                        this.rerWasteTypeListSubject.next(newWtyps);
                    }
                    return response;
                }
            )
        );
    }

    public getWasteTypeById(id: number): any {
        const wasteTypes = this.rerWasteTypeListSubject.getValue();
        const wt = wasteTypes.find((obj: RerWasteType) => obj.id === id);
        if (wt !== undefined) {
            const wtyp = JSON.stringify(wasteTypes.find((obj: RerWasteType) => obj.id === id));
            return JSON.parse(wtyp);
        }

        return null;
    }

    public saveRerBinOwnership(rerBinOwnership: RerBinOwnership): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rer.addEditRerBinOwnership'), rerBinOwnership).pipe(
            map(
                (response: number) => {
                    rerBinOwnership.id = response;
                    const newrbo = JSON.stringify(rerBinOwnership);

                    const rbos = this.rerBinOwnershipListSubject.getValue();
                    const newrbos = rbos.slice(0);
                    const rboIndex = rbos.findIndex((cl: RerBinOwnership) => cl.id === rerBinOwnership.id);

                    if (rboIndex !== -1) {
                        newrbos[rboIndex] = JSON.parse(newrbo);
                    } else {
                        newrbos.unshift(JSON.parse(newrbo));
                    }

                    this.rerBinOwnershipListSubject.next(newrbos);
                    return response;
                }
            )
        );
    }

    public deleteRerBinOwnership(rboId: number): Observable<any> {
        return this.httpClient.delete(this.endpointsService.get('rer.deleteRerBinOwnership', [rboId])).pipe(
            map(
                (response) => {
                    const rbos = this.rerBinOwnershipListSubject.getValue();
                    const newrbos = rbos.slice(0);
                    const rboIndex = newrbos.findIndex((ca: RerBinOwnership) => ca.id === rboId);
                    if (rboIndex !== -1) {
                        newrbos.splice(rboIndex, 1);
                        this.rerBinOwnershipListSubject.next(newrbos);
                    }
                    return response;
                }
            )
        );
    }

    public getRerBinOwnershipById(id: number): any {
        const rerBinOwnerships = this.rerBinOwnershipListSubject.getValue();
        const wt = rerBinOwnerships.find((obj: RerBinOwnership) => obj.id === id);
        if (wt !== undefined) {
            const wtyp = JSON.stringify(rerBinOwnerships.find((obj: RerBinOwnership) => obj.id === id));
            return JSON.parse(wtyp);
        }

        return null;
    }

    public getDeviceById(id: number): any {
        const devices = this.rerDevicelListSubject.getValue();
        const dev = devices.find((obj: Device) => obj.id === id);
        if (dev !== undefined) {
            const device = JSON.stringify(devices.find((obj: Device) => obj.id === id));
            return JSON.parse(device);
        }

        return null;
    }

    public getBinTypeList(): Observable<any> {
        return this.httpClient.get<any[]>(this.endpointsService.get('rer.getBinTypeList'));
    }

    public saveBinType(binType: RerBinType): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rer.addEditBinType'), binType).pipe(
            map(
                (response: number) => {
                    binType.id = response;
                    const newBtyp = JSON.stringify(binType);

                    const btyps = this.rerBinTypeListSubject.getValue();
                    const newBtyps = btyps.slice(0);
                    const bTypIndex = btyps.findIndex((cl: RerBinType) => cl.id === binType.id);

                    if (bTypIndex !== -1) {
                        newBtyps[bTypIndex] = JSON.parse(newBtyp);
                    } else {
                        newBtyps.unshift(JSON.parse(newBtyp));
                    }

                    this.rerBinTypeListSubject.next(newBtyps);
                    return response;
                }
            )
        );
    }

    public deleteBinType(binTypeId: number): Observable<any> {
        return this.httpClient.delete(this.endpointsService.get('rer.deleteBinType', [binTypeId])).pipe(
            map(
                (response) => {
                    const btyps = this.rerBinTypeListSubject.getValue();
                    const newbtyps = btyps.slice(0);
                    const wtypIndex = newbtyps.findIndex((ca: RerBinType) => ca.id === binTypeId);
                    if (wtypIndex !== -1) {
                        newbtyps.splice(wtypIndex, 1);
                        this.rerBinTypeListSubject.next(newbtyps);
                    }

                    return response;
                }
            )
        );
    }

    public getBinTypeById(id: number): RerBinType {
        const binTypes = this.rerBinTypeListSubject.getValue();
        const bt = binTypes.find((obj: RerBinType) => obj.id === id);
        if (bt !== undefined) {
            const btyp = JSON.stringify(binTypes.find((obj: RerBinType) => obj.id === id));
            return JSON.parse(btyp);
        }

        return null;
    }

    public saveArea(area: RerArea): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rer.addEditArea'), area).pipe(
            map(
                (response: number) => {
                    area.id = response;
                    const newArea = JSON.stringify(area);

                    const areas = this.rerAreaListSubject.getValue();
                    const newareas = areas.slice(0);
                    const areaIndex = areas.findIndex((cl: RerArea) => cl.id === area.id);

                    if (areaIndex !== -1) {
                        newareas[areaIndex] = JSON.parse(newArea);
                    } else {
                        newareas.unshift(JSON.parse(newArea));
                    }

                    this.rerAreaListSubject.next(newareas);
                    return response;
                }
            )
        );
    }

    public saveEmailTemplate(template: RerEmailTemplate) {
        return this.httpClient.post(this.endpointsService.get('rer.editEmailTemplate'), template).subscribe({
            next:
                (response) => {
                    template.id = response;
                    const templates = this.rerEmailTemplateListSubject.getValue();
                    const newtemplates = templates.slice(0);
                    const templateIndex = templates.findIndex((cl: RerEmailTemplate) => cl.id === template.id);
                    if (templateIndex !== -1) {
                        newtemplates[templateIndex] = template;
                    } else {
                        newtemplates.unshift(template);
                    }

                    this.rerEmailTemplateListSubject.next(newtemplates);
                    return response;
                }
        });
    }

    public deleteArea(areaId: number): Observable<any> {
        return this.httpClient.delete(this.endpointsService.get('rer.deleteArea', [areaId])).pipe(
            map(
                (response) => {
                    const areas = this.rerAreaListSubject.getValue();
                    const newareas = areas.slice(0);
                    const areaIndex = newareas.findIndex((ca: RerArea) => ca.id === areaId);
                    if (areaIndex !== -1) {
                        newareas.splice(areaIndex, 1);
                        this.rerAreaListSubject.next(newareas);
                    }
                    return response;
                }
            )
        );
    }

    public getAreaById(id: number): any {
        const areas = this.rerAreaListSubject.getValue();
        const ar = areas.find((obj: RerArea) => obj.id === id);
        if (ar !== undefined) {
            const area = JSON.stringify(areas.find((obj: RerArea) => obj.id === id));
            return JSON.parse(area);
        }

        return null;
    }

    public getEmailTemplateByID(id: number): any {
        const templates = this.rerEmailTemplateListSubject.getValue();
        const t = templates.find((obj: RerEmailTemplate) => obj.id === id);
        if (t !== undefined) {
            const template = JSON.stringify(templates.find((obj: RerEmailTemplate) => obj.id === id));
            return JSON.parse(template);
        }

        return null;
    }

    public getIncidentById(id: number): any {
        const incidents = this.rerIncidentListSubject.getValue();
        const inc = incidents.find((obj: RerIncident) => obj.id === id);
        if (inc !== undefined) {
            const area = JSON.stringify(incidents.find((obj: RerIncident) => obj.id === id));
            return JSON.parse(area);
        }

        return null;
    }
    public saveIncident(incident: RerIncident): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rer.editIncident'), incident).pipe(
            map(
                (response: { id: number }) => {
                    incident.id = response.id;
                    const newIncident = JSON.stringify(incident);

                    const incidents = this.rerIncidentListSubject.getValue();
                    const newincidents = incidents.slice(0);
                    const incidentIndex = incidents.findIndex((cl: RerIncident) => cl.id === incident.id);

                    if (incidentIndex !== -1) {
                        newincidents[incidentIndex] = JSON.parse(newIncident);
                    }

                    this.rerIncidentListSubject.next(newincidents);
                    return response;
                }
            )
        );
    }

    public deleteIncident(incidentId: number): Observable<any> {
        return this.httpClient.delete(this.endpointsService.get('rer.deleteIncident', [incidentId])).pipe(
            map(
                (response) => {
                    const incidents = this.rerIncidentListSubject.getValue();
                    const newincidents = incidents.slice(0);
                    const incidentIndex = newincidents.findIndex((ca: RerIncident) => ca.id === incidentId);
                    if (incidentIndex !== -1) {
                        newincidents.splice(incidentIndex, 1);
                        this.rerIncidentListSubject.next(newincidents);
                    }
                    return response;
                }
            )
        );
    }

    public setDateOffset(date_send: string, requestToServer = false, timeformat = 'YYYY-MM-DD HH:mm:ss') {
        const offset = new Date().getTimezoneOffset();
        const date = new Date(date_send);
        let date_msg = '';

        if (requestToServer) {
            date.setMinutes(date.getMinutes() + offset);
        } else {
            date.setMinutes(date.getMinutes() - offset);
        }

        date_msg = moment(date).format(timeformat);

        return date_msg;
    }

    public testSmtpSettings(mailSettings: any) {
        const token = this.authService.token;
        if (token === null) {
            this.authService.logout();
        }
        this.httpClient.post(this.endpointsService.get('rer.smtptest'), mailSettings, {observe: 'response'}).subscribe({
            next:(response) => this.smtpTestSubject.next(response),
            error:(error) => this.smtpTestSubject.next(error)
        });
    }

    public getRerBinSetupDocuments(filterData: { cityList: string, streetList: string, documentSigned:string}): Observable<{ rerBinSetupDocumentList: any[] }> {
        return this.httpClient.post<{ rerBinSetupDocumentList: any[] }>(
            this.endpointsService.get('rerbinsetupdocument.getRerBinSetupDocuments'), filterData).pipe(
                tap((data: { rerBinSetupDocumentList: any[] }) => {
                    const rawDocuments = data.rerBinSetupDocumentList;
                    if (rawDocuments) {
                        const documents = [];
                        rawDocuments.forEach(doc => documents.push(
                            new RerBinSetupDocument(
                                doc.id,
                                doc.number,
                                doc.rer_client_name,
                                doc.rer_client_id,
                                doc.signed_at,
                                doc.date,
                                doc.user_id,
                                null,
                                doc.count_bins, 
                                null,
                                null,
                                null,
                                null,
                                null,
                                doc.bin_type,
                                doc.waste_type,
                                doc.client_address, 
                                doc.responsible_person,
                                doc.placement_address,
                                doc.username,
                                doc.deleted_at,
                                doc.deleted_by
                            )
                        ));
    
                        this.rerBinSetupDocumentsSubject.next(documents);
                    }
                })
            );
    }

    public getBinSetupDocumentById(id: number): any {
        const downloaddocuments = this.rerBinSetupDocumentsSubject.getValue();
        const receipt = downloaddocuments.find((obj: RerBinSetupDocument) => obj.id === id);
        if (receipt !== undefined) {
            const weighingReceipt = JSON.stringify(downloaddocuments.find((obj: RerBinSetupDocument) => obj.id === id));
            return JSON.parse(weighingReceipt);
        }

        return null;
    }

    public deleteBinSetupDocument(rerBinSetupDocument): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rerbinsetupdocument.deleteBinSetupDocument'), rerBinSetupDocument).pipe(
            map(
                (response : RerBinSetupDocument ) => {
                    rerBinSetupDocument.id = response.id;
                    const documents = this.rerBinSetupDocumentsSubject.getValue();
                    const documentIndex = documents.findIndex((area: RerBinSetupDocument) => area.id === rerBinSetupDocument.id);
                    
                    documents[documentIndex].deleted_at = response.deleted_at;
                    documents[documentIndex].deleted_by = response.deleted_by;
                    
                    this.rerBinSetupDocumentsSubject.next(documents);
                    return response;
                },
            )
        );
    }

    public deleteClientBinSetupDocument(rerBinSetupDocument): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('rerbinsetupdocument.deleteBinSetupDocument'), rerBinSetupDocument).pipe(
            map(
                (response : RerBinSetupDocument ) => {
                    rerBinSetupDocument.id = response.id;
                    const documents = this.rerClientBinSetupDocumentListSubject.getValue();
                    const documentIndex = documents.findIndex((area: RerBinSetupDocument) => area.id === rerBinSetupDocument.id);
                    
                    documents[documentIndex].deleted_at = response.deleted_at;
                    documents[documentIndex].deleted_by = response.deleted_by;
                    
                    this.rerClientBinSetupDocumentListSubject.next(documents);
                    return response;
                },
            )
        );
    }
    
    public getRerBinSetupDocumentList(clientId: number) {
        return this.httpClient.get<any[]>(this.endpointsService.get('rerclient.listOfRerBinSetupDocument', [clientId])).subscribe({
            next: (response) => this.rerClientBinSetupDocumentListSubject.next(response)
        });
    }

    public getClientBinSetupDocumentById(id: number): any {
        const downloaddocuments = this.rerClientBinSetupDocumentListSubject.getValue();
        const receipt = downloaddocuments.find((obj: RerBinSetupDocument) => obj.id === id);
        if (receipt !== undefined) {
            const weighingReceipt = JSON.stringify(downloaddocuments.find((obj: RerBinSetupDocument) => obj.id === id));
            return JSON.parse(weighingReceipt);
        }

        return null;
    }

    public getRerStreetGeozoneList() {
        this.httpClient.get<RerCity[]>(this.endpointsService.get('rerstreetgeozone.getList')).subscribe({
            next:(response: RerCity[]) => this.loadStreetGeozones.next(response),
            error:() => noop
        });
    }

    public saveRerStreetGeozoneCity(city: RerCity): Observable<any> {
        return this.httpClient.post<RerCity[]>(this.endpointsService.get('rerstreetgeozone.saveCity'), city).pipe(
            tap(() => this.getRerStreetGeozoneList())
        );
    }
    
    public getStreetGeozoneCityById(id: number) {
        const cities = this.loadStreetGeozones.getValue().slice(0);
        const city = cities.find((el:RerCity) => el.id === id);
        
        return {...city};
    }

    public getRouteById(id: number) {
        const routes = this.loadRoutes.getValue().slice(0);
        const route = routes.find((el:RerRoute) => el.id === id);
        
        return {...route};
    }

    public saveRerStreetGeozoneStreet(street: RerStreet): Observable<any> {
        return this.httpClient.post<RerCity[]>(this.endpointsService.get('rerstreetgeozone.saveStreet'), street).pipe(
            tap(() => this.getRerStreetGeozoneList())
        );
    }

    public deleteRerStreetGeozoneCity(id: number): Observable<any> {
        return this.httpClient.get<number>(this.endpointsService.get('rerstreetgeozone.deleteCity') + id).pipe(
            tap(() => this.getRerStreetGeozoneList())
        );
    }

    public deleteRerStreetGeozoneStreet(id: number): Observable<any> {
        return this.httpClient.get<number>(this.endpointsService.get('rerstreetgeozone.deleteStreet') + id).pipe(
            tap(() => this.getRerStreetGeozoneList())
        );
    }

    public getRerRouteList() {
        this.httpClient.get<RerRoute[]>(this.endpointsService.get('rerroute.getList')).subscribe({
            next:(response: RerRoute[]) => this.loadRoutes.next(response),
            error:() => noop
        });
    }

    public saveRerRoute(route: RerRoute): Observable<any> {
        return this.httpClient.post<RerRoute>(this.endpointsService.get('rerroute.saveRoute'), route).pipe(
            tap(() => this.getRerRouteList())
        );
    }

    public deleteRerRoute(id: number): Observable<any> {
        return this.httpClient.get<number>(this.endpointsService.get('rerroute.deleteRoute') + id).pipe(
            tap(() => this.getRerRouteList())
        );
    }

    public getRerStreets(data: string): Observable<any> {
        return this.httpClient.get<any>(this.endpointsService.get('rerroute.getRerStreets') + data);
    }

    public getRoutePlanningSearchData(data: { date: string, streetId: number, deviceId: number }): Observable<any> {
        return this.httpClient.post<any>(this.endpointsService.get('rerroute.getRoutePlanningSearchData'), data);
    }

    public getRoutePlanningData(planningId: number): Observable<any> {
        return this.httpClient.get<any>(this.endpointsService.get('rerroute.getRoutePlanningExecutionData') +  planningId);
    }

    public getRerRouteStreets(id:number, data: { streets: number[] }): Observable<any> {
        return this.httpClient.post<any>(this.endpointsService.get('rerroute.saveRerRouteStreets') + id, data).pipe(
            tap(() => this.getRerRouteList())
        );
    }
}
