import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { environment } from "environments/environment";

import { SettingsService } from "app/modules/service/settings/settings.service";

@Component({
    selector: 'app-step2',
    templateUrl: './token-anaf-step2.component.html',
    styleUrls: ['./token-anaf-step2.component.scss']
})
export class TokenAnafStep2Component implements OnInit { 

    public showError = false;
    public showSpinner = false;

    constructor(private route: ActivatedRoute, private settingsService: SettingsService) { }

    /**
     * Initializes the component by subscribing to query parameters
     * and checking ANAF authorization code.
     * 
     * Acesta se executa cand anaf-ul acceseaza ruta de webhoof /token-anaf/step2
     * Poate veni cu success si atunci anaful trimite ca query parameter code ce reprezinta codul cu care se va obtine JWT Token-ul, poate veni cu un json sau cu parametru de eroare.
     * Componenta trateaza doar succes-ul, verifica doar daca este trimis parametr-ul code cu valoare.
     * Acest parametru code impreuna cu ds-ul clientului (id-ul acestuia), userId-ul, token-ul este trimis catre api-dsx care va obtine JWT Tokenul de la ANAF si il va salva pe ds-ul clientului
     * in caz de success fereastra se inchide dupa 3 secude astfel incat clientul revine la fereastra anterioara unde este afisat mesajul de succes
     */
    ngOnInit(): void {
        this.showSpinner = true;
        this.route.queryParamMap.subscribe({
            next: (params: ParamMap) => {
                const code: string | null = params.get('code');
                if (!code || code.length === 0) {
                    this.handleError();
                    return;
                }

                const anafData: string | null = localStorage.getItem('anafData');
                if (!anafData || anafData.length === 0) {
                    this.handleError();
                    return;
                }

                const data: { userId: string; token: string; dsId: string } = JSON.parse(anafData);
                const paramsAnaf = { 
                    code, 
                    userId: data.userId, 
                    token: data.token, 
                    dsId: +data.dsId 
                };
                const onServer = environment.production ? `https://on.cargotrack.ro/` : `https://ts1.cargotrack.ro/`;
                this.settingsService.checkAnafAuthorizationCode(onServer, paramsAnaf).subscribe({
                    next: () => {
                        this.showSpinner = false;
                        setTimeout(() => window.close(), 3000);
                    },
                    error: () => this.handleError()
                });
            },
            error: () => this.handleError()
        });
    }

    public handleError(): void {
        this.showSpinner = false;
        this.showError = true;
    }
}