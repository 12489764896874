<div class="d-flex align-items-center justify-content-center h-100">
    <p *ngIf="!showError; else error">
        <span *ngIf="!showSpinner">{{ 'Settings.etransportTab.tokenSuccessfullyGenerated' | translate }}</span>
    </p>

    <ng-template #error>
        <p>{{ 'Settings.etransportTab.obtainingJWTTokenError' | translate }}</p>
    </ng-template>

    <div *ngIf="showSpinner" class="d-flex align-self-center justify-content-center spinner w-100">
        <mat-spinner></mat-spinner>
    </div>
</div>